import {
    RegisterAccountState,
    RegisterContractState,
    RegisterInvoiceState,
    RegisterPersonalState,
    RegisterTariffState,
    RegisterVoucherState,
    RegisterState
} from './register.state';
import { Action } from '@ngrx/store';


export const REGISTER_ADD_ACCOUNT = 'REGISTER_ADD_ACCOUNT';
export const REGISTER_ADD_TARIFF = 'REGISTER_ADD_TARIFF';
export const REGISTER_ADD_PERSONAL_DETAILS = 'REGISTER_ADD_PERSONAL_DETAILS';
export const REGISTER_ADD_VOUCHER = 'REGISTER_ADD_VOUCHER';
export const REGISTER_VALIDATE_VOUCHER = 'REGISTER_VALIDATE_VOUCHER';
export const REGISTER_SAVE = 'REGISTER_SAVE';
export const REGISTER_SAVE_SUCCESS = 'REGISTER_SAVE_SUCCESS';
export const REGISTER_SAVE_FAILURE = 'REGISTER_SAVE_FAILURE';
export const REGISTER_ADD_INVOICE = 'REGISTER_ADD_INVOICE';


export interface RegisterAction extends Action {
    readonly type: string;
    payload: RegisterAccountState | RegisterTariffState | RegisterPersonalState |
        RegisterInvoiceState | RegisterVoucherState | RegisterContractState | RegisterState;
}

export class RegisterAddAccountAction implements RegisterAction {
    readonly type = REGISTER_ADD_ACCOUNT;

    constructor(public payload: RegisterAccountState) {
    }
}

export class RegisterAddTariffAction implements RegisterAction {
    readonly type = REGISTER_ADD_TARIFF;

    constructor(public payload: RegisterTariffState) {
    }
}

export class RegisterAddVoucherAction implements RegisterAction {
    readonly type = REGISTER_ADD_VOUCHER;

    constructor(public payload: RegisterVoucherState) {
    }
}

// @TODO we schold create a extra validation action cicle in the next sprint, this is buggy at the moment
export class RegisterValidateVoucherAction implements RegisterAction {
    readonly type = REGISTER_VALIDATE_VOUCHER;

    constructor(public payload: RegisterVoucherState) {
    }
}

export class RegisterAddPersonalDetailsAction implements RegisterAction {
    readonly type = REGISTER_ADD_PERSONAL_DETAILS;

    constructor(public payload: RegisterPersonalState) {
    }
}

export class RegisterAddInvoiceAction implements RegisterAction {
    type = REGISTER_ADD_INVOICE;

    constructor(public payload: RegisterInvoiceState) {
    }
}

export class RegisterSave implements RegisterAction {
    readonly type = REGISTER_SAVE;
    constructor(public payload: RegisterState) {
    }
}

export class RegisterSaveSuccess implements RegisterAction {
    readonly type = REGISTER_SAVE_SUCCESS;
    payload = null;
}

export class RegisterSaveFailure implements RegisterAction {
    readonly type = REGISTER_SAVE_FAILURE;
    payload = null;
    constructor(public errors: string[]) { }
}
