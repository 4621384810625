<div class="page-wrapper" *ngIf="(brandingSettings$ | async) as brandingSetting">
    <app-toggle-side-bar [outsideControl]="helpControl$">
        <app-doctor-questions [brandingSettings]="brandingSetting" *ngIf="questions === 'doctor'"></app-doctor-questions>
        <app-patients-questions [brandingSettings]="brandingSetting" *ngIf="questions === 'patient'"></app-patients-questions>
    </app-toggle-side-bar>
    <div class="page-side">
        <app-branded-header [brandingSettings]="brandingSetting" [profileType]="questions" (helpClicked)="helpControl$.next($event)">
        </app-branded-header>
        <ng-content></ng-content>
        <app-footer></app-footer>
    </div>
</div>
