import { Component, Input, OnInit } from '@angular/core';
import { CustomTranslateService } from '../services/custom-translate.service';
import { BrandingService } from '../../../modules/branding/providers/branding.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageMapper } from '../models/translation-mapper';

@Component({
    selector: 'app-translation',
    templateUrl: './translation.component.html',
    styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
    @Input() cssClass: string;
    public brandingSettings$: Observable<string[]>;
    public languageSelected;

    constructor(private customTranslateService: CustomTranslateService,
                private brandingService: BrandingService) {
        this.brandingSettings$ = this.brandingService.getBrandingSettingsFromStore().pipe(
            map((brandingSettings) => {
                const newLang = [];
                brandingSettings.lang.forEach(function(lang) {
                    newLang.push(LanguageMapper.languageToDisplayLanguage(lang));
                });
                return newLang;
            } )
        );
        this.languageSelected = this.customTranslateService.getLangFromStore();
    }

    ngOnInit(): void {
        this.customTranslateService.getLangFromStore().subscribe(
            (defaultValue) => {
                this.languageSelected = LanguageMapper.languageToDisplayLanguage(defaultValue);
            }
        );
    }

    selectLanguage(): void {
        this.customTranslateService.changeLang(LanguageMapper.displayLanguageToLanguage(this.languageSelected));
    }
}
