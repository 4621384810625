import {
    Directive,
    ElementRef,
    Input,
    OnChanges
} from '@angular/core';

export interface CssVarsObj {
    [key: string]: string;
}

@Directive({
    selector: '[appCssVars]'
})
export class CssVarsDirective implements OnChanges {

    @Input() appCssVars: CssVarsObj;

    constructor(private el: ElementRef) {
    }

    ngOnChanges() {
        if (!this.appCssVars) {
            return;
        }
        Object.keys(this.appCssVars).forEach(key => {
            if (!!this.appCssVars[key]) {
                this.el.nativeElement.style.setProperty(key, this.appCssVars[key]);
            }
        });
    }
}
