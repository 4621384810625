<form class="register" [formGroup]="voucherForm" role="form" novalidate>
    <div class="row">
        <div>
            <div class="box_content">
                <div class="row">
                    <div class="col-7">
                        <input type="text" name="voucher_code" formControlName="voucherCode" value=""
                               (keyup)="voucherCodeChange()">
                    </div>
                    <div class="col-4">
                        <input id="check_voucher" class="" type="submit"
                               value="{{ 'REGISTER.VOUCHER.CHECK' | translate }}">
                        <br style="clear:both;">
                    </div>
                </div>
                <div *ngIf="voucherForm.hasError('invalidVoucher')" class="alert alert-danger">
                    {{ 'REGISTER.VOUCHER.ERROR.INVALID_VOUCHER' | translate }}
                </div>
                <div class="checkbox voucher_agreement" *ngIf="voucherForm.controls['voucherCode'].value">
                    <div
                        *ngIf="!voucherForm.controls['voucherAgreement'].valid && voucherForm.controls['voucherAgreement'].touched"
                        class="error">
                        <div *ngIf="voucherForm.controls['voucherAgreement'].errors?.required" class="error">
                            {{'REGISTER.VOUCHER.ERROR.MISSING_AGREEMENT' | translate }}
                        </div>
                    </div>
                    <input type="checkbox" formControlName="voucherAgreement" id="checkbox3">
                    <label for="checkbox3">{{'REGISTER.VOUCHER.AGREEMENT' | translate }}</label>
                </div>
                <!-- <input class="" data-step="4" type="submit" value="{{ 'REGISTER.GENERAL.NEXT_STEP' | translate }}"> -->
            </div>
        </div>
    </div>
</form>
