import { ProfileType } from '../profile/store/profile.entity';
import { LOGIN_ROUTE, TAN_LOGIN_ROUTE } from '../../routes';

export enum PatientusRole {
    DOCTOR = 'doctor',
    PATIENT = 'patient',
    ANONYMOUS = 'anonymous'
}

export class AuthRole {
    public static getRoleNameByProfileType(type: ProfileType): PatientusRole {
        switch (type) {
            case ProfileType.DOCTOR:
                return PatientusRole.DOCTOR;
            case ProfileType.PATIENT:
                return PatientusRole.PATIENT;
            default:
                return PatientusRole.ANONYMOUS;
        }
    }

    public static getLogoutRouteByAuthRole(role: PatientusRole): string {
        switch (role) {
            case PatientusRole.DOCTOR:
                return LOGIN_ROUTE;
            case PatientusRole.PATIENT:
                return TAN_LOGIN_ROUTE;
            default:
                return TAN_LOGIN_ROUTE;
        }
    }
}
