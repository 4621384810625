import { BrandingState, InitBrandingState } from './branding.state';
import {
    BRANDING_LOAD_4SIGMA,
    BRANDING_LOAD_CUSTOM_BRANDING,
    BRANDING_LOAD_DEFAULT,
    BRANDING_REMOVE_ALL,
    BrandingAction
} from './branding.action';

export function brandingReducer(state: BrandingState = new InitBrandingState(), action: BrandingAction): BrandingState {
    switch (action.type) {
        case BRANDING_LOAD_CUSTOM_BRANDING: {
            return <BrandingState>action.payload;
        }
        case BRANDING_REMOVE_ALL:
        case BRANDING_LOAD_DEFAULT: {
            return new InitBrandingState();
        }
        default: {
            return state;
        }
    }
}
