<div class="start-survey-body">
    <div class="body-headline clearfix padding">
        <h5>{{'SURVEY_OVERLAY.START.HEADLINE' | translate }}</h5>
    </div>
    <div class="body-content padding">
        <div class="button-space">
            <button type="button" class="btn btn-secondary btn-block" (click)="startSurvey()">
                {{'SURVEY_OVERLAY.START.START_A_SURVEY' | translate }} <i class="fas fa-arrow-right"></i>
            </button>
        </div>
    </div>
</div>
