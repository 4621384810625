import { Languages } from './languages';

export enum DisplayLanguage {
    DE = 'Deutsch',
    EN = 'English'
}

export class LanguageMapper {
    static displayLanguageToLanguage(displayLanguage: DisplayLanguage): Languages {
        switch (displayLanguage) {
            case DisplayLanguage.DE:
                return Languages.DE;
            case DisplayLanguage.EN:
                return Languages.EN;
            default:
                return  Languages.DE;
        }
    }

    static languageToDisplayLanguage(language: Languages): DisplayLanguage {
        switch (language) {
            case Languages.DE:
                return DisplayLanguage.DE;
            case Languages.EN:
                return  DisplayLanguage.EN;
            default:
                return DisplayLanguage.DE;
        }
    }
}
