<a class="tarif_details" (click)='open()'>{{ 'REGISTER.TARIFF.DETAILS' | translate }}</a>
<div class="sweet-overlay" tabindex="-1" [ngClass]="{'visible': visible, 'hide': !visible}"></div>
<div class="sweet-alert showSweetAlert" [ngClass]="{'visible ': visible, 'hide ': !visible}">
    <div class="rateDetails">
        <div *ngFor="let detail of details" class="box  {{detail.icon}}">
            <p><b>{{detail.heading}}</b></p>
            <p><span>{{detail.text}}</span></p>
        </div>
        <div class="sa-button-container">
            <div class="sa-confirm-button-container">
                <button class="confirm" tabindex="1" (click)='close()'>{{ 'REGISTER.TARIFF.POPUP_BUTTON' | translate
                    }}
                </button>
            </div>
        </div>
    </div>
</div>
