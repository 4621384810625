import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { Survey } from '../model/survey.model';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class SurveyService {
    private DOCTOR = 'doctor';
    private PATIENT = 'patient';

    private surveyEndpoint = environment.getSurveyEndpoint;

    constructor(private http: HttpClient) {
    }

    public getDoctorSurvey(): Observable<Survey> {
        return this.getSurvey(this.surveyEndpoint + '/' + this.DOCTOR);
    }

    public getPatientSurvey(): Observable<Survey> {
        return this.getSurvey(this.surveyEndpoint + '/' + this.PATIENT);
    }

    public getSurvey(url: string): Observable<Survey> {
        return this.http
            .get<Survey>(url, {headers: new HttpHeaders().set('Content-Type', 'application/json')})
            .pipe(
                retry(3),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in SurveyService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
