import { AppState as RootAppState } from '../../../providers/store/app.state';
import { Languages } from '../models/languages';


export interface TranslateState {
    defaultLanguage: Languages;
}

export class InitialTranslateState implements TranslateState {
    defaultLanguage: Languages = Languages.DE;
}

export interface AppState extends RootAppState {
    language: TranslateState;
}
