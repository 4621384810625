import { Store, StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { ProfileComponent } from './profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { profileReducer } from './store/profile.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProfileEffects } from './store/profile.effect';
import { ProfileService } from './providers/profile.service';
import { ProfileListService } from './providers/profile-list.service';
import { ProfileSelectService } from './providers/profile-select.service';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrandingModule } from '../branding/branding.module';
import { FeatureFlagService } from '../feature-flag/providers/feature-flag.service';

const loginRoutes: Routes = [
    // { path: 'login', component: LogoutComponent }
];

@NgModule({
    declarations: [
        ProfileComponent
    ],
    imports: [
        NgbModule,
        CommonModule,
        RouterModule.forChild(loginRoutes),
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('profile', profileReducer),
        EffectsModule.forFeature([ProfileEffects]),
        BrandingModule
    ],
    exports: [
        RouterModule,
        ProfileComponent
    ],
    providers: [
        ProfileService,
        ProfileListService,
        ProfileSelectService,
        AuthGuard,
        Store,
        FeatureFlagService
    ]
})

export class ProfileModule {
}
