import { LoggedInState, LoggedOutState, LoginFailedState, LoginStateInterface, } from './login.state';
import {
    LOGIN_DO_FORCE_LOGOUT, LOGIN_DO_LOGOUT, LOGIN_FAILED, LOGIN_REFRESH_JWT, LOGIN_SUCCESS, LoginActionInterface,
    LoginRefreshJWTAction
} from './login.action';


export function loginReducer(state: LoginStateInterface = new LoggedOutState(), action: LoginActionInterface): LoginStateInterface {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return new LoggedInState(action.jwt);
        case LOGIN_FAILED:
            return new LoginFailedState(action.error);
        case LOGIN_DO_LOGOUT:
        case LOGIN_DO_FORCE_LOGOUT:
            return new LoggedOutState();
        case LOGIN_REFRESH_JWT:
            return {...state, jwt: (<LoginRefreshJWTAction>action).jwt};
        default:
            return state;
    }
}
