import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileModule } from '../profile/profile.module';
import { headerReducer } from './store/header.reducer';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BrandedHeaderComponent } from './components/branded-header/branded-header.component';
import { HeaderComponent } from './components/header/header.component';
import { BrandingModule } from '../branding/branding.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule as CustomTranslateModule} from '../../providers/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ProfileModule,
        StoreModule.forFeature('header', headerReducer),
        TranslateModule.forChild(),
        BrandingModule,
        ReactiveFormsModule,
        FormsModule,
        CustomTranslateModule
    ],
    declarations: [
        HeaderComponent,
        BrandedHeaderComponent
    ],
    exports: [
        HeaderComponent,
        BrandedHeaderComponent
    ],
})
export class HeaderModule {
}

