<app-fixed-black-background *ngIf="isOverlayVisible === true">

    <div class="text-wrapper">
        <h5>{{ 'MODAL.STOP-INFO.MESSAGE' | translate }}</h5>
        <div class="flex center">
            <button class="button outlined"
                    (click)="backToWaitingroomClicked()">{{ 'MODAL.STOP-INFO.BTN' | translate}}
            </button>
        </div>
    </div>

</app-fixed-black-background>