import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { BrandingService } from './providers/branding.service';
import { brandingReducer } from './store/branding.reducer';
import { CssVarsDirective } from './directives/css-vars.directive';

@NgModule({
    imports: [
        StoreModule.forFeature('branding', brandingReducer),
    ],

    providers: [
        BrandingService
    ],
    declarations: [CssVarsDirective],
    exports: [
        CssVarsDirective
    ]
})
export class BrandingModule {
}
