import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BrandingService } from '../branding/providers/branding.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        RouterModule
    ],
    providers: [
        BrandingService
    ],
    declarations: [HelpComponent],
    exports: [HelpComponent]
})
export class HelpModule {
}
