import {map} from 'rxjs/operators';
import {AppState} from '../store/register.state';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { Apollo, MutationResult } from 'apollo-angular';
import {RegisterDataType} from './register-graphql.types';
import gql from 'graphql-tag';

import {Store} from '@ngrx/store';

export interface RegisterReturnType {
    createDoctor: {
        success: boolean;
        error: string;
    };
}

@Injectable()
export class RegisterService {
    private mutation = gql`
        mutation CreateNewDoctor($registerData: RegisterDataInputType){
            createDoctor(registerData: $registerData) {
                success
                error
            }
        }`;

    constructor(private apollo: Apollo, private store: Store<AppState>) {
    }

    public registerDoctor(registerData: RegisterDataType): Observable<string> {
        return this.apollo.mutate({
                mutation: this.mutation,
                variables: registerData
            }
        ).pipe(
            map((fetchResult: MutationResult<RegisterReturnType>) => {
                if (fetchResult.hasOwnProperty('errors') && fetchResult.errors) {
                    throw new Error('GraphQlErrors');
                } else {
                    return fetchResult.data.createDoctor;
                }
            }),
            map((s: { success: boolean; error: string }) => {
                if (s.success === true) {
                    return 'success';
                } else {
                    throw new Error(s.error);
                }
            })
        );
    }
}
