import { Component } from '@angular/core';
import { ModalCloseAction } from '../../store/modal.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/modal.state';

@Component({
    selector: 'app-safari-switch-recommendation',
    templateUrl: './safari-switch-recommendation.component.html',
    styleUrls: ['./safari-switch-recommendation.component.scss']
})
export class SafariSwitchRecommendationComponent {

    constructor(private store: Store<AppState>) {
    }

    continueAnyway() {
        this.store.dispatch(new ModalCloseAction());
    }
}
