import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-toggle-side-bar',
    templateUrl: './toggle-side-bar.component.html',
    styleUrls: ['./toggle-side-bar.component.scss']
})
export class ToggleSideBarComponent implements OnInit, OnDestroy {

    @Input() outsideControl: Observable<boolean>;
    private outsideControlSubscription: Subscription = null;
    openSidebar = false;

    constructor() {
    }

    ngOnInit() {
        this.outsideControlSubscription = this.outsideControl.subscribe(event => this.openSidebar = event);
    }

    ngOnDestroy() {
        if (this.outsideControlSubscription !== null) {
            this.outsideControlSubscription.unsubscribe();
        }
    }

    toggleSidebar() {
        this.openSidebar = !this.openSidebar;
    }
}
