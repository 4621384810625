import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../../auth.service';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    DoLoginAction,
    DoTanLoginAction,
    LOGIN_DO_FORCE_LOGOUT,
    LOGIN_DO_LOGIN,
    LOGIN_DO_LOGOUT,
    LOGIN_DO_TAN_LOGIN,
    LoginFailedAction,
    LoginSuccessAction,
    LOGOUT_SUCCESS,
    LogoutSuccessAction
} from './login.action';
import {NgxPermissionsService} from 'ngx-permissions';
import {AuthRole, PatientusRole} from '../../auth.roles';
import {RouterHelperService} from '../../../utils/router-helper/router-helper.service';

@Injectable()
export class LoginEffects {
    constructor(private authService: AuthService,
                private actions$: Actions,
                private permissionsService: NgxPermissionsService,
                private router: RouterHelperService) {
    }

     doLogin$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LOGIN_DO_LOGIN),
        map(toCredentials),
        exhaustMap((credential) => this.authService
                .credentialAuthentication(credential).pipe(
                    map((jwt) => new LoginSuccessAction(jwt)),
                    catchError((err) => of(new LoginFailedAction(err)))))));

     doTanLogin$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LOGIN_DO_TAN_LOGIN),
        map(toTan),
        exhaustMap((tan) => this.authService
                .tanAuthentication(tan).pipe(
                    map((jwt) => new LoginSuccessAction(jwt)),
                    catchError((err) => of(new LoginFailedAction(err)))))));

     doForceLogout$ = createEffect(() => this.actions$.pipe(
        ofType(LOGIN_DO_FORCE_LOGOUT),
        tap(_ => window.location.reload())), {dispatch: false});


    // todo refactor this stuff after updating permission library
     doLogout$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LOGIN_DO_LOGOUT),
        map(() => this.permissionsService.getPermissions()),
        switchMap(((permission: { [key: string]: { name: PatientusRole } }): PatientusRole[] => Object.keys(permission)
            .map(key => <PatientusRole>permission[key].name))),
        map((role: PatientusRole) => AuthRole.getLogoutRouteByAuthRole(role)),
        map((route: string) => new LogoutSuccessAction(route))));

     doLogoutAndFlushPermissions$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LOGOUT_SUCCESS),
        tap(_ => this.permissionsService.flushPermissions())), {dispatch: false});

     doRedirectToPublicPage$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LOGOUT_SUCCESS),
        tap(action => this.router.navigate([(<LogoutSuccessAction>action).payload]))), {dispatch: false});
}

const toCredentials = action => (<DoLoginAction>action).credentials;
const toTan = action => (<DoTanLoginAction>action).tan;
