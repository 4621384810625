import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { ProfileModule } from '../profile/profile.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrandingModule } from '../branding/branding.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule as CustomTranslateModule } from '../../providers/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ProfileModule,
        TranslateModule.forChild(),
        BrandingModule,
        ReactiveFormsModule,
        FormsModule,
        CustomTranslateModule
    ],
    declarations: [
        FooterComponent
    ],
    exports: [
        FooterComponent
    ]
})
export class FooterModule {
}
