import { TRANSLATE_CHANGE_LANGUAGE, TranslateActionInterface } from './translate.action';
import { InitialTranslateState, TranslateState } from './translate.state';

export function translateReducer(
    state: TranslateState = new InitialTranslateState(),
    action: TranslateActionInterface
): TranslateState {
    switch (action.type) {
        case TRANSLATE_CHANGE_LANGUAGE:
            return {...state, defaultLanguage: action.language};
        default:
            return state;
    }
}
