<div class="one-question-answer-space">
    <h5>{{ 'LOGIN.FAQ.PROFILE.1.Q' | translate }}</h5>
    <p [innerHTML]="'LOGIN.FAQ.PROFILE.1.A1' | translate | safeHtml"></p>
    <span class="notice">{{ 'LOGIN.FAQ.PROFILE.1.N1' | translate }}</span>
    <p>{{ 'LOGIN.FAQ.PROFILE.1.A2' | translate }}</p>
    <span class="notice">{{ 'LOGIN.FAQ.PROFILE.1.N2' | translate }}</span>
    <br/>
    <span [innerHTML]="(brandingSettings.translationKeys?.helpSection?.doctor || brandingSettings.translationKeys?.helpSection || 'HELP.DR.DEFAULT') | translate: {
            faq: (brandingSettings.faqLink === false ? '' : 'HEADER-QUESTIONS.FAQ' | translate)
        } | safeHtml">
    </span>
</div>
