import {Injectable} from '@angular/core';
import {NextStepFrom} from '../navigation/navigation.types';


@Injectable()
export class ScrollService {

    public scrollTo(stepNumber: string) {
        let scrolledY: number;
        const headerHeight = 80;
        const navList: Element = document.querySelector('#ul-nav');
        const element: Element = document.querySelector('#step' + stepNumber);
        const navElement: Element = document.querySelector('#nav' + stepNumber);

        if (element) {
            element.scrollIntoView(true);
            scrolledY = window.scrollY;
            if (scrolledY) {
                window.scroll(0, scrolledY - headerHeight);
            }
        }

        Array.from(navList.children).forEach((child: HTMLLIElement) => {
            if (child.firstElementChild.className === 'active') {
                child.firstElementChild.firstElementChild.className = 'hidden';
                child.firstElementChild.className = 'inactive';
            }
        });

        navElement.className = 'active';
        navElement.firstElementChild.className = 'mobile_invisible';
    }

    public scrollToStep(nextStep: NextStepFrom) {
        this.scrollTo(nextStep.toString());
    }
}
