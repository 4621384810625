import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store/login/login.state';
import { LoginRefreshJWTAction } from './store/login/login.action';

@Injectable()
export class AuthRefreshJWTInterceptor implements HttpInterceptor {

    constructor(private store: Store<AppState>) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                const refreshJWT = event.headers.get('X-Patientus-Refresh-JWT');

                if (refreshJWT !== null && refreshJWT !== '') {
                    this.store.dispatch(new LoginRefreshJWTAction(refreshJWT));
                }
            }
        }));
    }
}
