import { AppState as RootAppState } from '../../../providers/store/app.state';

export interface WaitingroomState {
    peerReady: boolean;
    rtcDetectionModalManuallyClosed: boolean;
    camIsOn: boolean;
    onCall: boolean;
}

export interface AppState extends RootAppState {
    waitingroom: WaitingroomState;
}

export class WaitingroomInitialState implements WaitingroomState {
    peerReady = false;
    rtcDetectionModalManuallyClosed = false;
    camIsOn: false;
    onCall: false;
}
