import { Component } from '@angular/core';
import { AppState } from '../../store/modal.state';
import { Store } from '@ngrx/store';
import { ModalCloseAction } from '../../store/modal.action';

@Component({
    selector: 'app-download-chrome-browser',
    templateUrl: './download-chrome-browser.component.html',
    styleUrls: ['./download-chrome-browser.component.scss']
})
export class DownloadChromeBrowserComponent {

    public downloadChromeBrowser = 'https://www.google.com/chrome/';

    constructor(private store: Store<AppState>) {
    }

    continueAnyway() {
        this.store.dispatch(new ModalCloseAction());
    }

    downloadGoogleChrome() {
        window.open(this.downloadChromeBrowser, '_blank');
    }
}
