import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store/login/login.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    jwt: string;

    constructor(private store: Store<AppState>) {
        const jwtSelect$ = this.store.select(appStore => appStore.login.jwt);
        jwtSelect$.subscribe(s => this.jwt = s);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.jwt != null) {
            const authReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + this.jwt)});
            return next.handle(authReq);
        } else {
            return next.handle(req);
        }
    }
}
