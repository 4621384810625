import { HOME_ROUTE } from '../../../../routes';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouterHelperService } from '../../../utils/router-helper/router-helper.service';

@Component({
    selector: 'app-registartion-successful',
    templateUrl: './registartion-successful.component.html',
    styleUrls: ['../../../../info-pages-styles.scss', './registartion-successful.component.scss']
})
export class RegistartionSuccessfulComponent {

    constructor(private translate: TranslateService, private router: RouterHelperService) {
    }

    public goToHome(): void {
        this.router.navigate([HOME_ROUTE]);
    }
}
