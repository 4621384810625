import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRole, PatientusRole } from './auth.roles';
import { ProfileService } from '../profile/providers/profile.service';

@Injectable()
export class AuthPermissionService {

    constructor(private profileService: ProfileService) {
    }

    public getCurrentRole(): Observable<PatientusRole> {
        return this.profileService.getCurrentProfileTypeObserver().pipe(
            map(AuthRole.getRoleNameByProfileType)
        );
    }
}
