export enum Step {
    ACCOUNT = 1,
    TARIFF,
    PERSONAL,
    INVOICE,
    CONTRACT
}

export enum NextStepFrom {
    ACCOUNT = Step.TARIFF,
    TARIFF,
    PERSONAL,
    INVOICE
}
