import { filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export enum GeneralStateType {
    STATE_PEER_CONNECTED = 'STATE_PEER_CONNECTED',
    STATE_COUNT_DOWN_START = 'STATE_COUNT_DOWN_START',
    STATE_COUNT_DOWN_STOP = 'STATE_COUNT_DOWN_STOP',
    STATE_CLOSE_CALL = 'STATE_CLOSE_CALL'
}

interface State {
    currentType: GeneralStateType;
    nextType: GeneralStateType;

    handle(appContext: AppContext): void;
}

class PeerConnectedState implements State {
    public currentType = GeneralStateType.STATE_PEER_CONNECTED;
    public nextType = GeneralStateType.STATE_COUNT_DOWN_START;

    public handle(appContext: AppContext): void {
        appContext.State = new CountDownStartState();
    }
}

class CountDownStartState implements State {
    public currentType = GeneralStateType.STATE_COUNT_DOWN_START;
    public nextType = GeneralStateType.STATE_COUNT_DOWN_STOP;

    public handle(appContext: AppContext): void {
        appContext.State = new CountDownStopState();
    }
}

class CountDownStopState implements State {
    public currentType = GeneralStateType.STATE_COUNT_DOWN_STOP;
    public nextType = GeneralStateType.STATE_CLOSE_CALL;

    public handle(appContext: AppContext): void {
        appContext.State = new CloseCallState();
    }
}

class CloseCallState implements State {
    public currentType = GeneralStateType.STATE_CLOSE_CALL;
    public nextType = GeneralStateType.STATE_COUNT_DOWN_START;

    public handle(appContext: AppContext): void {
        appContext.State = new CountDownStartState();
    }
}


class AppContext {
    private state: State;

    constructor(state: State) {
        this.state = state;
    }

    get State(): State {
        return this.state;
    }

    set State(state: State) {
        this.state = state;
    }

    public next(): void {
        this.state.handle(this);
    }
}

@Injectable()
export class GeneralMessageBusService {
    private appContext: AppContext;
    private currentState$ = new Subject<State>();

    constructor() {
        this.init();
    }

    public add(eventType: GeneralStateType): void {
        if (this.appContext.State.nextType !== eventType) {
            throw new Error(`Not valid Event type ${eventType}`);
        }
        this.appContext.next();
        this.currentState$.next(this.appContext.State);
    }

    public listen(eventType: GeneralStateType): Observable<State> {
        return this.currentState$.pipe(filter(x => x.currentType === eventType));
    }

    private init() {
        this.appContext = new AppContext(new PeerConnectedState());
        this.currentState$.next(this.appContext.State);
    }
}
