import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SettingPageComponent } from './settings-page/setting-page.component';
import { PaymentPageComponent } from './payment-page/payment-page.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([]),
        TranslateModule.forChild()
    ],
    declarations: [
        SettingPageComponent,
        PaymentPageComponent
    ],
    exports: [
        SettingPageComponent,
        PaymentPageComponent
    ]
})
export class PagesModule {
}
