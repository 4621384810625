import { EntityStateProfile, initialProfileListState, Profile, profileAdapter } from './profile.entity';
import {
    PROFILE_ACCEPT_DATA_PROCESS_SUCESS,
    PROFILE_ADD,
    PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL,
    PROFILE_DATA_REQUESTING,
    PROFILE_LIST_RECEIVED_SUCCESSFUL,
    PROFILE_REMOVE_ALL,
    PROFILE_UPDATE,
    ProfileAcceptDataProcessSuccessAction,
    ProfileAction,
    ProfileAddAction,
    ProfileDataReceivedSuccessfulAction,
    ProfileListReceivedSuccessfulAction,
    ProfileUpdateAction,
} from './profile.action';
import { APP_CHANGE_TO_ANONYMOUS_STATE } from '../../../providers/store/app.action';

export function profileReducer(
    state: EntityStateProfile = initialProfileListState,
    action: ProfileAction): EntityStateProfile {
    switch (action.type) {
        case PROFILE_DATA_REQUESTING: {
            return {
                ...state,
                isInit: true
            }
        }
        case PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL: {
            const profile: Profile = (<ProfileDataReceivedSuccessfulAction>action).payload;
            const newState: EntityStateProfile = profileAdapter.addOne(profile, state);
            newState.currentId = profile.id;
            return newState;
        }
        case PROFILE_LIST_RECEIVED_SUCCESSFUL: {
            const profiles: Profile[] = (<ProfileListReceivedSuccessfulAction>action).payload;
            return {
              ...profileAdapter.addMany(profiles, state),
              isInit: false
            };
        }
        case PROFILE_ADD: {
            const profile: Profile = (<ProfileAddAction>action).payload;
            profile.id = Number(profile.id);
            const newState: EntityStateProfile = profileAdapter.addOne(profile, state);
            return newState;
        }
        case PROFILE_ACCEPT_DATA_PROCESS_SUCESS: {
            const profile: Profile = (<ProfileAcceptDataProcessSuccessAction>action).payload;
            profile.id = Number(profile.id);
            const newState: EntityStateProfile = profileAdapter.updateOne({id: profile.id, changes: profile}, state);
            return newState;
        }

        case PROFILE_UPDATE: {
            const profile: Profile = (<ProfileUpdateAction>action).payload;
            profile.id = Number(profile.id);
            const newState: EntityStateProfile = profileAdapter.updateOne({id: profile.id, changes: profile}, state);
            return newState;
        }
        case APP_CHANGE_TO_ANONYMOUS_STATE: {
            return initialProfileListState;
        }
        case PROFILE_REMOVE_ALL: {
            return initialProfileListState;
        }
        default: {
            return state;
        }
    }
}

