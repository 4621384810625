import {Action} from '@ngrx/store';

export const SIDEBAR_SET_REMOTE_VISIBILITY = 'SIDEBAR_SET_REMOTE_VISIBILITY';
export const SIDEBAR_HIDE = 'SIDEBAR_HIDE';

export interface SidebarAction extends Action {
    payload: boolean;
}

export class SidebarSetRemoteVisibilityAction implements SidebarAction {
    readonly type = SIDEBAR_SET_REMOTE_VISIBILITY;

    constructor(public payload) {
    }
}

export class SidebarHideAction implements SidebarAction {
    readonly type = SIDEBAR_HIDE;

    constructor(public payload) {
    }
}
