<div id="header" class="header">
    <div class="upper-part">
        <div class="content-wrapper">
            <div class="logo-space"
                 [ngClass]="{'no-padding-top': brandingSettings.removeLogoSpacePaddingTop}">
                <img *ngIf="brandingSettings.logoUrl"
                     [src]="brandingSettings.logoUrl"
                     class="header-logo" alt="Logo">
                <img *ngIf="!brandingSettings.logoUrl" src="/assets/img/general/logo_new.svg"
                     class="header-logo" alt="Logo">
            </div>
            <div *ngIf="!brandingSettings.removePatientusLogo && brandingSettings.logoUrl"
                 class="powered-by-space">
                <img src="/assets/img/branding/powered-by-patientus-white.svg" class="powered-by-img" alt="Logo">
            </div>
            <div class="language-section"
                 *ngIf="brandingSettings.lang && brandingSettings.lang.length > 1">
                <app-translation [cssClass]="'header'"></app-translation>
            </div>
        </div>
    </div>
    <div class="lower-part">
        <div class="content-wrapper">
            <p class="help-tex roboto weight-300">
            <span [innerHTML]="(brandingSettings.translationKeys?.headerSection[profileType] || brandingSettings.translationKeys?.headerSection || 'HEADER-QUESTIONS.DEFAULT') | translate: {
                    faq: (brandingSettings.faqLink === false ? '' : 'HEADER-QUESTIONS.FAQ' | translate),
                    email: brandingSettings.email || ('PATIENTUS.SERVICE_EMAIL' | translate),
                    telephone: brandingSettings.telephone || ('PATIENTUS.PHONE' | translate),
                    callTime: brandingSettings.callTime || ('HEADER.TIME-AVAILABLE' | translate)
                }"></span>
            </p>
        </div>
    </div>
</div>
