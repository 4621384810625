import { Component } from '@angular/core';
import { ModalCloseAction } from '../../store/modal.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/modal.state';

@Component({
    selector: 'app-outdated-browser-version',
    templateUrl: './outdated-browser-version.component.html',
    styleUrls: ['./outdated-browser-version.component.scss']
})
export class OutdatedBrowserVersionComponent {

    public downloadChromeBrowser = 'https://www.google.com/chrome/';

    constructor(private store: Store<AppState>) {
    }

    continueAnyway() {
        this.store.dispatch(new ModalCloseAction());
    }

    downloadGoogleChrome() {
        window.open(this.downloadChromeBrowser, '_blank');
    }
}
