<div class="tab-list-header">
    <div class="content-wrapper">
        <div class="tab-list-item active"
             [routerLink]="[paymentAccountLink]"
             [ngClass]="{'active': isActiveTab(paymentAccountLink )}">
            <span>{{ 'PAYMENT.TABS.ACCOUNT' | translate }}</span>
        </div>
        <div class="tab-list-item"
             [routerLink]="[paymentInfoLink]"
             [ngClass]="{'active': isActiveTab(paymentInfoLink)}">
            <span>{{ 'PAYMENT.TABS.INFO' | translate }}</span>
        </div>
    </div>
</div>

<router-outlet></router-outlet>

