<div *ngFor="let number of [1,2,3]" class="one-question-answer-space">
    <h5>{{ 'LOGIN.FAQ.TAN.' + number + '.Q' | translate }}</h5>
    <p>{{ 'LOGIN.FAQ.TAN.' + number + '.A' | translate }}</p>
</div>
<div class="one-question-answer-space"
    [innerHTML]="(brandingSettings.translationKeys?.helpSection?.patient || brandingSettings.translationKeys?.helpSection || 'HELP.PATIENT.DEFAULT') | translate: {
    faq: (brandingSettings.faqLink === false ? '' : 'HEADER-QUESTIONS.FAQ' | translate),
    email: brandingSettings.email || ('PATIENTUS.SERVICE_EMAIL' | translate),
    telephone: brandingSettings.telephone || ('PATIENTUS.PHONE' | translate),
    callTime: brandingSettings.callTime || ('HEADER.TIME-AVAILABLE' | translate)
    }">
</div>
