<div id="header" class="clearfix" [ngClass]="{
    'collapsed': (remoteVideoVisibility$ | async),
    'hide-header-logo': (hideHeaderLogo$ | async),
    'no-padding-top': (brandingSettings$ | async).removeLogoSpacePaddingTop}">
    <img *ngIf="(remoteVideoVisibility$ | async)" [src]="(logoUrls$ | async).smallLogoUrl"
         class="header-logo" alt="Logo">
    <img *ngIf="!(remoteVideoVisibility$ | async)" [src]="(logoUrls$ | async).logoUrl"
         class="header-logo" alt="Logo">
    <img *ngIf="!(remoteVideoVisibility$ | async)" [src]="(logoUrls$ | async).smallLogoUrl"
         class="mobile-header-logo" alt="Logo">
    <div class="header-profile">
        <img src="{{(profileData$ | async)?.imageUrl}}" alt="" class="profile-image">
        <div class="header-text-wrapper clearfix">
            <span class="profile-name">{{(profileData$ | async)?.fullName}}</span>
            <a id="logout" *ngIf="(isAuthenticated$ |async) " (click)="logout()">
                <span><i class="fas fa-sign-out"></i>{{'LOGOUT' | translate}}</span>
            </a>
        </div>
    </div>
</div>
