import {
    RegisterAccountState,
    RegisterInitialState,
    RegisterInvoiceState,
    RegisterPersonalState,
    RegisterState,
    RegisterTariffState,
    RegisterVoucherState,
} from './register.state';
import {
    REGISTER_ADD_ACCOUNT,
    REGISTER_ADD_INVOICE,
    REGISTER_ADD_PERSONAL_DETAILS,
    REGISTER_ADD_TARIFF,
    REGISTER_ADD_VOUCHER,
    REGISTER_SAVE,
    REGISTER_SAVE_FAILURE,
    REGISTER_SAVE_SUCCESS,
    RegisterAction
} from './register.action';

export function registerReducer(state: RegisterState = new RegisterInitialState(), action: RegisterAction): RegisterState {

    switch (action.type) {
        case REGISTER_ADD_ACCOUNT:
            state.account = <RegisterAccountState>action.payload;
            return state;
        case REGISTER_ADD_TARIFF:
            state.tariff = <RegisterTariffState>action.payload;
            return state;
        case REGISTER_ADD_VOUCHER:
            state.voucher = <RegisterVoucherState>action.payload;
            return state;
        case REGISTER_ADD_PERSONAL_DETAILS:
            state.personal = <RegisterPersonalState>action.payload;
            return state;
        case REGISTER_ADD_INVOICE:
            state.invoice = <RegisterInvoiceState>action.payload;
            return state;
        case REGISTER_SAVE:
            return state;
        case REGISTER_SAVE_SUCCESS:
            state = new RegisterInitialState();
            return state;
        case REGISTER_SAVE_FAILURE:
            return state;
        default:
            return state;
    }
}
