import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Appointment } from '../../appointment/store/one-time-appointment/appointment.entity';
import { Store } from '@ngrx/store';
import { selectCurrentProfile } from '../store/profile.selector';
import { MapService } from './map.service';
import { environment } from '../../../../environments/environment';
import { FeatureFlagService } from '../../feature-flag/providers/feature-flag.service';
import { FeatureFlag } from '../../feature-flag/feature-flag.types';
import {
    EntityStateProfile,
    Profile,
    ProfileListResponse,
    ProfileResponse,
    profileTypeMap
} from '../store/profile.entity';

@Injectable()
export class ProfileListService {
    private profileEndpoint: string;
    private currentProfileId: number;

    constructor(private http: HttpClient, featureFlagService: FeatureFlagService, store: Store<EntityStateProfile>) {
        const isMultiUserCallEnabled = featureFlagService.isFeatureEnabled(FeatureFlag.MULTI_USER_CALL);
        this.profileEndpoint = isMultiUserCallEnabled ? environment.multiUserProfileListEndpoint : environment.profileListEndpoint;
        store.select(selectCurrentProfile).subscribe(profile => {
            if (profile) {
                this.currentProfileId = Number(profile.id);
            }
        });
    }

    public getProfileList(aList: Appointment[]): Observable<Profile[]> {
        const ids = this.extractProfileIds(aList);
        if (!ids) {
            return of();
        }

        return this.http.get(this.profileEndpoint + ids, {}).pipe(
            map((profileList: Map<number | string, ProfileResponse>) => this.mapToProfileEntity(Object.values(profileList))));
    }

    public getProfileListV2(aList: Appointment[]): Observable<Profile[]> {
        const ids = this.extractProfileIds(aList);
        if (!ids) {
            return of();
        }

        return this.http.post(this.profileEndpoint, {items: ids}).pipe(
            map((profileList: ProfileListResponse) => this.mapToProfileEntity(profileList.items))
        );
    }

    private extractProfileIds(aList: Appointment[]): number[] {
        const ids: Set<number> = new Set();
        aList.forEach(a => {
            a.profileIds.forEach(id => {
                if (Number(id) !== this.currentProfileId) {
                    ids.add(Number(id));
                }
            });
        });
        return Array.from(ids).sort((a, b) => a - b);
    }

    private mapToProfileEntity(profileResponseList: ProfileResponse[]): Profile[] {
        const profileList: Profile[] = [];
        profileResponseList.forEach(function (profileResponse: ProfileResponse) {
            const type = typeof profileResponse.type === 'string' ? profileTypeMap(profileResponse.type) : profileResponse.type;
            const profile = {
                id: Number(profileResponse.id),
                type,
                fullName: profileResponse.fullName,
                firstName: profileResponse.firstName,
                lastName: profileResponse.lastName,
                salutation: profileResponse.salutation,
                title: profileResponse.title,
                specialities: profileResponse.specialities,
                imageUrl: MapService.getProfileImage(profileResponse.imageUrl),
                email: profileResponse.email,
                ...(profileResponse.tan ? {tan: profileResponse.tan} : {}),
                ...(profileResponse.birthday ? {birthday: profileResponse.birthday} : {}),
                ...(profileResponse.insuranceNumber ? {insuranceNumber: profileResponse.insuranceNumber} : {})
            };
            profileList.push(profile);
        });
        return profileList;
    }
}
