import { Routes } from '@angular/router';
import { RegisterComponent } from './register.component';
import { AuthGuardExternal } from '../auth/auth-guard-external.service';
import { REGISTER_ROUTE } from '../../routes';



export const registerRoutes: Routes = [
    { path: REGISTER_ROUTE, component: RegisterComponent, pathMatch: 'full', canActivate: [AuthGuardExternal] },
];
