import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import {
    PROFILE_ACCEPT_DATA_PROCESS,
    PROFILE_LOAD_CURRENT_PROFILE_FROM_BACKEND,
    ProfileAcceptDataProcessAction,
    ProfileAcceptDataProcessFailedAction,
    ProfileAcceptDataProcessSuccessAction,
    ProfileDataReceivedFailedAction,
    ProfileDataReceivedSuccessfulAction,
    ProfileDataRequestedAction,
    ProfileListReceivedSuccessfulAction,
    ProfileRemoveAllAction
} from './profile.action';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ProfileService } from '../providers/profile.service';
import { APP_CHANGE_TO_ANONYMOUS_STATE } from '../../../providers/store/app.action';
import {
    APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL,
    AppointmentsInitialReceivedSuccessfulAction
} from '../../appointment/store/one-time-appointment/appointment.action';
import { Appointment } from '../../appointment/store/one-time-appointment/appointment.entity';
import { Profile } from './profile.entity';
import { ProfileListService } from '../providers/profile-list.service';
import { Observable, of } from 'rxjs';
import { multiUserAppointmentsFetchSuccessAction } from '../../appointment/store/appointment/multi-user-appointment.action';
import { MultiUserAppointment } from '../../appointment/store/appointment/multi-user-appointment.entity';

@Injectable()
export class ProfileEffects {
    constructor(private actions$: Actions,
                private profileService: ProfileService,
                private profileListService: ProfileListService) {
    }

     doRequestProfileData$: Observable<Action> = createEffect(() => this.actions$.pipe(
        filter((action) => action.type === PROFILE_LOAD_CURRENT_PROFILE_FROM_BACKEND),
        switchMap(() => this.profileService
            .getCurrentProfileData().pipe(
                map((response) => new ProfileDataReceivedSuccessfulAction(response)),
                catchError((err) => of(new ProfileDataReceivedFailedAction(err)))
            )),));

     doAcceptDataProcessTerm$: Observable<Action[] | Action> = createEffect(() => this.actions$.pipe(
        ofType(PROFILE_ACCEPT_DATA_PROCESS),
        map((action: ProfileAcceptDataProcessAction) => action.payload),
        mergeMap((profile: Profile) => this.profileService
            .acceptDataProcess(profile).pipe(
                map((p: Profile) => new ProfileAcceptDataProcessSuccessAction(p)),
                catchError(err => of(new ProfileAcceptDataProcessFailedAction(err)))))));

     doRequestProfilesForAppointments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL),
        map((action: AppointmentsInitialReceivedSuccessfulAction) => action.payload),
        switchMap((appointments: Appointment[]) => this.profileListService
            .getProfileList(appointments).pipe(
                map((profileList: Profile[]) => new ProfileListReceivedSuccessfulAction(profileList)),
                catchError((err) => of(new ProfileDataReceivedFailedAction(err)))))));

     doRemoveProfiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        map(() => new ProfileRemoveAllAction())));

    doRequestProfileDataV2$ = createEffect(() => this.actions$.pipe(
        ofType(multiUserAppointmentsFetchSuccessAction),
        map((action) => {
            if (action.items?.length) {
                return new ProfileDataRequestedAction();
            } else {
                return new ProfileListReceivedSuccessfulAction([]);
            }
        })
    ));

    doRequestProfilesV2ForAppointments$ = createEffect(() => this.actions$.pipe(
        ofType(multiUserAppointmentsFetchSuccessAction),
        filter(action => action.items?.length > 0),
        map(action => action.items),
        switchMap((appointments: MultiUserAppointment[]) => this.profileListService
            .getProfileListV2(appointments).pipe(
                map((profileList: Profile[]) => new ProfileListReceivedSuccessfulAction(profileList)),
                catchError((err) => of(new ProfileDataReceivedFailedAction(err)))
            )
        )
    ));

}

