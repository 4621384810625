import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { ProfileService } from '../profile/providers/profile.service';
import { AuthRole, PatientusRole } from './auth.roles';
import { Observable, of, throwError as _throw } from 'rxjs';

@Injectable()
export class AuthService {

    constructor(private http: HttpClient,
                private permissionsService: NgxPermissionsService,
                private profileService: ProfileService) {
        this.subscribeCurrentProfileRoles();
    }

    public credentialAuthentication(credentials): Observable<string> {
        const data = {
            email: credentials.email,
            password: credentials.password
        };

        return this.http.post(environment.credentialAuthEndpoint, data, {
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
        }).pipe(switchMap(result => {
            if (result.hasOwnProperty('id_token')) {
                const bearerToken = (<AuthResponse>result).id_token.toString();
                if (bearerToken && bearerToken.length > 1) {
                    return of(bearerToken);
                } else {
                    return _throw(new Error('No JWT Token exits'));
                }
            } else {
                return _throw(new Error('Wrong Response Object'));
            }
        }));
    }

    public tanAuthentication(tan): Observable<string> {
        const data = {
            hash: tan
        };

        return this.http.post(environment.tanAuthEndpoint, data, {
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
        }).pipe(switchMap(result => {
            if (result.hasOwnProperty('id_token')) {
                const bearerToken = (<AuthResponse>result).id_token.toString();
                if (bearerToken && bearerToken.length > 1) {
                    return of(bearerToken);
                } else {
                    return _throw(new Error('No JWT Token exits'));
                }
            } else {
                return _throw(new Error('Wrong Response Object'));
            }
        }));
    }

    subscribeCurrentProfileRoles() {
        const profileObserver = this.profileService.getCurrentProfileTypeObserver();
        profileObserver.subscribe((profileType) => {
            if (profileType !== undefined) {
                const roles: PatientusRole[] = [];
                roles.push(AuthRole.getRoleNameByProfileType(profileType));
                this.permissionsService.loadPermissions(roles);
            }
        });
    }
}

interface AuthResponse {
    id_token: string;
}
