<div class="overflow-wrapper">
    <div class="announces help">
        <img class="header-illustration" src="/assets/img/illustrations/help.png" alt="">
        <div class="content-wrapper">
            <ng-template ngxPermissionsOnly="doctor">
                <h2>{{ 'HELP.DR.HEADER' | translate}}</h2>
                <p>{{ 'HELP.DR.SUBTITLE' | translate}}</p>
                <span class="bold">{{ 'HELP.DR.TIPS.HEADER' | translate}}</span>
                <ul>
                    <li><a href="https://www.patientus.de/anwendungsbeispiele" target="_blank">{{ 'HELP.DR.TIPS.ITEM1' |
                        translate}}</a></li>
                    <li><a href="https://www.patientus.de/integration" target="_blank">{{ 'HELP.DR.TIPS.ITEM2' |
                        translate}}</a></li>
                    <li><a href="https://www.patientus.de/bekanntmachung" target="_blank">{{ 'HELP.DR.TIPS.ITEM3' |
                        translate}}</a></li>
                </ul>
            </ng-template>
            <ng-template ngxPermissionsOnly="patient">
                <h2>{{ 'HELP.PATIENT.HEADER' | translate}}</h2>
                <p>{{ 'HELP.PATIENT.SUBTITLE' | translate}}</p>
                <span class="bold">{{ 'HELP.PATIENT.TIPS.HEADER' | translate}}
                    <a href="{{ 'HELP.PATIENT.TIPS.FAQ-LINK' | translate}}" target="_blank">
                        {{ 'HELP.PATIENT.TIPS.FAQ' | translate}}
                    </a>
                </span>
                <ul>
                    <li>
                        <a href="https://faq.patientus.de/portal/kb/articles/was-mache-ich-wenn-der-arzt-zur-vereinbarten-zeit-nicht-da-ist"
                           target="_blank">{{ 'HELP.PATIENT.TIPS.ITEM2' | translate}}</a></li>
                    <li>
                        <a href="https://faq.patientus.de/portal/kb/articles/wie-kann-ich-den-arzt-benachrichtigen-dass-ich-den-termin-absagen-muss-nicht-wahrnehmen-kann"
                           target="_blank">{{ 'HELP.PATIENT.TIPS.ITEM1' | translate}}</a></li>
                    <li>
                        <a href="https://faq.patientus.de/portal/kb/articles/was-mache-ich-wenn-ich-technische-probleme-habe"
                           target="_blank">{{ 'HELP.PATIENT.TIPS.ITEM3' | translate}}</a></li>
                    <li>
                        <a href="https://faq.patientus.de/portal/kb/articles/wie-kann-ich-feststellen-ob-meine-internetverbindung-schnell-genug-ist"
                           target="_blank">{{ 'HELP.PATIENT.TIPS.ITEM4' | translate}}</a></li>
                </ul>
            </ng-template>
        </div>
    </div>
</div>
<div class="help-content">
    <h3>{{ 'HELP.CONTACTS.HEADER' | translate}}</h3>
    <div class="help-updates">
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <p>{{ 'HELP.CONTACTS.REACHUS' | translate}}</p>
                <p class="bold">{{ 'HELP.CONTACTS.REACHUS_TIME' | translate}}</p>
            </div>
            <div class="col-md-6 col-xs-12">
                <a class="btn btn-primary rounded" id="serviceTelephone"
                href="tel:{{ (brandingData$ | async)?.telephone ? (brandingData$ | async)?.telephone : ('PATIENTUS.PHONE' | translate)}}">
                <i class="fa fa-phone"></i>
                    {{ (brandingData$ | async)?.telephone ? (brandingData$ | async)?.telephone : ('PATIENTUS.PHONE' | translate) }}
                </a>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <p>{{ 'HELP.CONTACTS.OR_WRITE' | translate }}</p>
                <p class="bold">{{ 'HELP.CONTACTS.ONE_MAIL' | translate }}</p>
            </div>
            <div class="col-md-6 col-xs-12">
                <a class="btn btn-primary rounded" id="serviceEmail" href="mailto:{{ (brandingData$ | async)?.email ? (brandingData$ | async)?.email : ('PATIENTUS.SERVICE_EMAIL' | translate)}}" >
                    <i class="fa fa-envelope"></i> {{ (brandingData$ | async)?.email ? (brandingData$ | async)?.email : ('PATIENTUS.SERVICE_EMAIL' | translate)}}</a>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <p>{{ 'HELP.CONTACTS.OR_FEEDBACK' | translate }}</p>
                <p class="bold">{{ 'HELP.CONTACTS.FEEDBACK_FORMULAR' | translate }}</p>
            </div>
            <div class="col-md-6 col-xs-12">
                <a class="btn btn-primary rounded"
                   href="{{ 'GENERAL.SURVEY-BASE-LINK' | translate }}?id={{ (profileData$ | async)?.id }}&email={{ (profileData$ | async)?.email }}"
                   target="_blank">
                    <i class="fa fa-paper-plane"></i> {{ 'HELP.CONTACTS.FEEDBACK_BTN' | translate }}</a>
            </div>
        </div>
    </div>
</div>
