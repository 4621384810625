import {HeaderInitialState, HeaderState} from './header.state';
import {HEADER_SET_REMOTE_VISIBILITY, HeaderAction} from './header.action';

export function headerReducer(state: HeaderState = new HeaderInitialState(), action: HeaderAction): HeaderState {
    switch (action.type) {
        case HEADER_SET_REMOTE_VISIBILITY: {
            return {
                remoteVideoVisible: action.payload,
                hideHeaderLogo: action.payload
            };
        }

        default: {
            return state;
        }
    }
}
