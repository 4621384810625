import {SidebarInitialState, SidebarState} from './sidebar.state';
import {
    SIDEBAR_SET_REMOTE_VISIBILITY,
    SidebarAction
} from './sidebar.action';

export function sidebarReducer(state: SidebarState = new SidebarInitialState(), action: SidebarAction): SidebarState {
    switch (action.type) {
        case SIDEBAR_SET_REMOTE_VISIBILITY: {
            return {
                remoteVideoVisible: action.payload,
                hideSideBar: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
