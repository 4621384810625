import {Observable, of} from 'rxjs';

import {catchError, map, mapTo, switchMap, tap} from 'rxjs/operators';
import {Action} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    REGISTER_SAVE,
    REGISTER_SAVE_FAILURE,
    REGISTER_SAVE_SUCCESS,
    RegisterSave,
    RegisterSaveFailure,
    RegisterSaveSuccess
} from './register.action';
import {RegisterService} from '../providers/register.service';
import {RegisterState} from './register.state';
import {Router} from '@angular/router';
import {MapService} from '../providers/map.service';
import {RegisterDataType} from '../providers/register-graphql.types';


import {REGISTER_FAILED_ROUTE, REGISTER_SUCCESSFUL_ROUTE} from '../../../routes';

@Injectable()
export class RegisterEffects {
     doSave$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(REGISTER_SAVE),
        map((action: RegisterSave) => action.payload),
        map((data: RegisterState) => MapService.convert(data)),
        switchMap((data: RegisterDataType) => this.registerService
                .registerDoctor(data).pipe(
                    mapTo(new RegisterSaveSuccess()),
                    catchError((err) => of(new RegisterSaveFailure(err)))))));

     doRedirectToSuccessRoute$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(REGISTER_SAVE_SUCCESS),
        tap(() => {
            this.router.navigate([REGISTER_SUCCESSFUL_ROUTE]);
        })), {dispatch: false});

     doRedirectToFailedRoute$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(REGISTER_SAVE_FAILURE),
        tap(() => {
            this.router.navigate([REGISTER_FAILED_ROUTE]);
        })), {dispatch: false});

    constructor(private registerService: RegisterService,
                private actions$: Actions,
                private router: Router) {
    }
}
