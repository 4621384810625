import { catchError, filter, map, mergeMap, retry, tap } from 'rxjs/operators';
import { Conference, SingleConferenceToken } from '../store/conference.model';
import { Store } from '@ngrx/store';
import { selectAllConferences, selectToken } from '../store/conference.selectors';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConferenceState } from '../store/conference.reducer';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AddToken } from '../store/conference.actions';

export interface ConferenceIdList {
    conferenceIds: number[];
}

@Injectable()
export class TokenService {

    constructor(
        private store: Store<ConferenceState>,
        private http: HttpClient
    ) {
    }

    public getRoomAuthorisationToken(): Observable<string> {
        return this.getAllConferences().pipe(
            filter((conferences: Conference[]) => conferences.length > 0),
            map((conferences: Conference[]) => conferences.map(conference => conference.id)),
            map((conferenceIds: number[]) => <ConferenceIdList>{conferenceIds}),
            mergeMap((conferenceIdList: ConferenceIdList) => this.getTokenFromBackend(conferenceIdList)),
            tap((token: string) => this.store.dispatch(new AddToken({token})))
        );
    }

    public getAllConferences(): Observable<Conference[]> {
        return this.store.select(selectAllConferences);
    }

    public getToken(): Observable<string> {
        return this.store.select(selectToken);
    }

    public getSingleConferenceToken(payload: SingleConferenceToken): Observable<string> {
        return this.http.post<{ 'jwt': string }>(environment.conferenceTokenEndpoint, payload)
            .pipe(
                retry(3),
                map(r => r.jwt),
                catchError(this.handleError)
            );
    }

    public getConferenceOnlineToken(): Observable<string> {
        return this.http.post<{ 'jwt': string }>(environment.conferenceOnlineTokenEndpoint, null)
            .pipe(
                retry(3),
                map(r => r.jwt),
                catchError(this.handleError)
            );
    }

    private getTokenFromBackend(conferenceIdList: ConferenceIdList): Observable<string> {
        return this.http.post<{ 'jwt': string }>(environment.conferenceTokenEndpoint, conferenceIdList)
            .pipe(
                retry(3),
                map(r => r.jwt),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred in ConferenceTokenService:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an ErrorObservable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }
}
