import { Component } from '@angular/core';

@Component({
  selector: 'app-soft-note-tax',
  templateUrl: './soft-note-tax.component.html',
  styleUrls: ['./soft-note-tax.component.scss']
})
export class SoftNoteTaxComponent {

  constructor() { }

}
