<ng-container *ngIf="showSurvey">
    <app-popup-container
        [icon]='"<i class=\"fal fa-clipboard\"></i>"'
        (closeClicked)="closeSurvey($event)">
        <app-start-survey-overlay
            *ngIf="!surveySelected"
            (openSurvey)="startSurvey($event)">
        </app-start-survey-overlay>
        <iframe
            *ngIf="surveySelected"
            class="survey-selected"
            [src]="surveyLink| safe">
        </iframe>
    </app-popup-container>
</ng-container>
