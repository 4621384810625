import { AppState as RootAppState } from '../../../../providers/store/app.state';
import { HttpErrorResponse } from '@angular/common/http';


export interface LoginStateInterface {
    loggedIn: boolean;
    error?: HttpErrorResponse;
    jwt: string;
}

export class LoggedOutState implements LoginStateInterface {
    loggedIn = false;
    jwt = null;
}

export class LoggedInState implements LoginStateInterface {
    loggedIn = true;

    constructor(public jwt: string) {
    }
}

export class LoginFailedState implements LoginStateInterface {
    loggedIn = false;
    jwt = null;
    constructor(public error: HttpErrorResponse) {
    }
}

export interface AppState extends RootAppState {
    login: LoginStateInterface;
}
