import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState, ModalCategory } from '../store/modal.state';
import { ModalCloseAction, ModalOpenSurveyAction } from '../store/modal.action';
import { Subscription } from 'rxjs';
import { GeneralMessageBusService, GeneralStateType } from '../../message-bus/providers/general-message-bus.service';

@Component({
    selector: 'app-confirm-close-overlay',
    templateUrl: './confirm-close-overlay.component.html',
    styleUrls: ['./confirm-close-overlay.component.scss']
})
export class ConfirmCloseOverlayComponent implements OnDestroy {
    confirmCloseOverlaySubscription: Subscription = null;
    public isOverlayVisible = false;

    constructor(private translate: TranslateService,
                private generalMessageBusService: GeneralMessageBusService,
                private store: Store<AppState>) {
        this.confirmCloseOverlaySubscription = this.store.select(s => s.modal).subscribe(modalStore => {
                this.isOverlayVisible = (modalStore.isActive && (modalStore.category === ModalCategory.CONFIRM));
            }
        );
    }

    closeCallClicked() {
        this.generalMessageBusService.add(GeneralStateType.STATE_CLOSE_CALL);
        this.store.dispatch(new ModalOpenSurveyAction());
    }

    cancelClicked() {
        this.store.dispatch(new ModalCloseAction());
    }

    ngOnDestroy(): void {
        if (this.confirmCloseOverlaySubscription !== null) {
            this.confirmCloseOverlaySubscription.unsubscribe();
        }
    }
}
