<app-fixed-black-background>
    <div class="text-wrapper">
        <div class="flex center icon-display">
            <i class="fal fa-exclamation-triangle fa-5x"></i>
        </div>
        <div class="flex center">
            <h5>{{ 'MODAL.BROWSER-DETECT-OVERLAY.MOBILE-CHROME-RECOMMENDATION.TITLE' | translate }}</h5>
        </div>
        <div class="flex center">
            <p class="description">
                {{ 'MODAL.BROWSER-DETECT-OVERLAY.MOBILE-CHROME-RECOMMENDATION.DESCRIPTION' | translate }}
            </p>
        </div>
        <div class="flex center">
            <button class="button underlined" (click)="continueAnyway()">
                {{ 'MODAL.BROWSER-DETECT-OVERLAY.MOBILE-CHROME-RECOMMENDATION.BUTTON.OKAY' | translate }}
            </button>
        </div>
    </div>
</app-fixed-black-background>
