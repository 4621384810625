import { take } from 'rxjs/operators';
import { ScrollService } from '../providers/scroll.service';
import {
    AppState,
    RegisterAccountState,
    RegisterInvoiceState,
    RegisterPersonalState,
    RegisterTariffState,
    RegisterVoucherState
} from '../store/register.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { RegisterSave } from '../store/register.action';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GERMAN_DEFAULT_BIC } from '../step-invoice/step-invoice.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-step-contract',
    templateUrl: './step-contract.component.html',
    styleUrls: ['./step-contract.component.scss']
})
export class StepContractComponent {
    termsForm: UntypedFormGroup;
    voucher$: Observable<RegisterVoucherState>;
    invoice$: Observable<RegisterInvoiceState>;
    personal$: Observable<RegisterPersonalState>;
    tariff$: Observable<RegisterTariffState>;
    account$: Observable<RegisterAccountState>;

    defaultBic: string = GERMAN_DEFAULT_BIC;

    validSteps = {account: false, tariff: false, personal: false, invoice: false};

    constructor(private store: Store<AppState>,
                private translate: TranslateService,
                private scroll: ScrollService,
                fb: UntypedFormBuilder) {
        this.account$ = this.store.select(state => state.register.account);
        this.tariff$ = this.store.select(state => state.register.tariff);
        this.personal$ = this.store.select(state => state.register.personal);
        this.invoice$ = this.store.select(state => state.register.invoice);
        this.voucher$ = this.store.select(state => state.register.voucher);
        this.termsForm = fb.group({terms: fb.control(null, Validators.compose([Validators.requiredTrue]))});
    }

    getCommaSeparated(list) {
        return list.map(function(elem) {
            return elem.itemName;
        }).join(', ');
    }

    scrollTo(stepNumber: string) {
        this.scroll.scrollTo(stepNumber);
    }

    onSubmit() {
        if (this.termsForm.valid) {
            this.store.select('register').pipe(take(1)).subscribe(data => {
                this.store.dispatch(new RegisterSave(data));
            });
        } else {
            this.termsForm.controls['terms'].markAsTouched();
        }
    }
}
