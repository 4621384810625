<div class="site-register">
    <app-register-navigation></app-register-navigation>
    <app-step-account></app-step-account>
    <app-step-tariff></app-step-tariff>
    <app-step-personal></app-step-personal>
    <app-soft-note-tax></app-soft-note-tax>
    <app-step-invoice></app-step-invoice>
    <app-soft-note-fee></app-soft-note-fee>
    <app-step-contract></app-step-contract>
</div>
<div class="register-footer">
    <app-footer></app-footer>
</div>
