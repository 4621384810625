<div class="page-wrapper">
    <div class="content-wrapper">
        <div class="header-image-wrapper success">
            <i class="fa fa-check fa-5x" aria-hidden="true"></i>
        </div>
        <h2>{{ 'REGISTER.SUCCESS.HEADER' | translate}}</h2>
        <h3>{{ 'REGISTER.SUCCESS.FOOTER-TEXT' | translate}}</h3>
        <button type="button" id='goToHome' (click)='goToHome()' class="btn btn-primary">
            {{ 'REGISTER.SUCCESS.HOME-BUTTON-TEXT' | translate }} <span class='fa fa-check-circle' aria-hidden="true"></span>
        </button>
    </div>
    <div class="content-wrapper contact">
        <p class="service-message">{{ 'PATIENTUS.SERVICE_MESSAGE' | translate}}</p>
        <p class="contact">
            <a href="mailto:{{'PATIENTUS.SERVICE_EMAIL' | translate }}">
                <i class="fas fa-envelope"></i> {{'PATIENTUS.SERVICE_EMAIL' | translate }}</a>
            <span class="split-element">|</span>
            <a href="tel:{{'PATIENTUS.PHONE' | translate }}">
                <i class="fas fa-phone"></i> {{'PATIENTUS.PHONE' | translate }}</a>
        </p>
    </div>
</div>
