import { AppState as RootAppState } from '../../../providers/store/app.state';
import { Languages } from '../../../providers/translate/models/languages';
import { VonageBrandingSettings } from '../../../providers/vonage-branding-settings/vonage-branding-settings';

/**
 * Interface for the BrandingState.
 */
export interface BrandingState {
    mainColor?: string;
    secondaryColor?: string;
    contrastColor?: string;
    hoverPrimaryColoredBtn?: string;
    hoverSecondaryColoredBtn?: string;
    coloredShadow?: string;
    fontColorOnSecondaryColor?: string;
    logoBackgroundColor?: string;
    logoUrl?: string;
    ovsLogoUrl?: string;
    smallLogoUrl?: string;
    fontColor?: string;
    bgImage_doctorLogin?: string;
    bgImage_patientLogin?: string;
    bgImage_patientWaitingRoom?: string;
    removeDoctorLink?: boolean;
    removePatientusLogo?: boolean;
    email?: string;
    telephone?: string;
    callTime?: string;
    faqLink?: boolean;
    feedbackLink?: string;
    certificateLink?: string;
    title?: string;
    removeAgbCheckbox?: boolean;
    hideDataProcessTerms?: boolean;
    legalTerms?: string;
    legalImprint?: string;
    legalDataSecurity?: string;
    legalCancellationPolicy?: string;
    hideBookableAppointment?: boolean;
    lang?: Languages[]; // Index 0 is the default language
    removeLogoSpacePaddingTop?: boolean;
    translationKeys?: BrandingTranslationKeys;
    vonageSettings?: VonageBrandingSettings;

}

interface BrandingTranslationKeys {
    headerSection?: string | {
        doctor: string;
        patient: string;
    };
    helpSection?: string | {
        doctor: string;
        patient: string;
    }
}

/**
 * Initial values of the branding state. Maybe we could define the default
 * Patientus values here.
 */
export class InitBrandingState implements BrandingState {
}

/**
 * Extend App States
 */
export interface AppState extends RootAppState {
    branding: BrandingState;
}
