import { distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/pre-init.state';

@Injectable()
export class PreInitConditionService {

    constructor(private store: Store<AppState>) {
    }

    public isPreInitCompleted(): Observable<boolean> {
        return this.store.select(s => s.preInit.completed).pipe(distinctUntilChanged());
    }
}
