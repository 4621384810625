<div id="step5" class="container">
    <div>
        <h2>
            <span>05</span> {{ 'REGISTER.CONTRACT-OVERVIEW.CONTRACT-CONCLUSION' | translate }}</h2>
        <div class="row">
            <div class="box_content col-12">
                <h4>{{ 'REGISTER.CONTRACT-OVERVIEW.OVERVIEW-OF-YOUR-DATE' | translate }}</h4>
                <p>{{ 'REGISTER.CONTRACT-OVERVIEW.CHECK-AND-EDIT-DATA' | translate }}</p>
            </div>
            <div class="col-lg-6 col-md-12"
                 id="overviewVoucher">
                <div class="data_box">
                    <div class="data_box_header">{{ 'REGISTER.VOUCHER.TITLE' | translate }}
                        <a>Edit</a>
                    </div>
                    <table class="content-table">
                        <tbody>
                        <tr>
                            <td>
                                <app-step-voucher></app-step-voucher>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div id="overviewAccount"
                     *ngIf="(account$ | async)?.email">
                    <div class="data_box">
                        <div class="data_box_header">01 {{ 'REGISTER.ACCOUNT.TITLE' | translate }}
                            <a (click)="scrollTo('1')">Edit</a>
                        </div>
                        <table class="content-table">
                            <tbody>
                            <tr>
                                <td>
                                    <b>{{ 'REGISTER.ACCOUNT.ACCOUNT_ID' | translate }}</b>
                                </td>
                                <td>{{ (account$ | async).email }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>{{ 'REGISTER.ACCOUNT.NEWS_LETTER' | translate }}</b>
                                </td>
                                <td *ngIf="(account$ | async)?.confirmNewsletter === true">{{ 'GENERAL.YES' | translate }}</td>
                                <td *ngIf="!(account$ | async)?.confirmNewsletter === true"> {{ 'GENERAL.NO' | translate }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="overviewTariff"
                         *ngIf="(tariff$ | async)?.tariff.name">
                        <div class="data_box">
                            <div class="data_box_header">02 {{ 'REGISTER.TARIFF.TITLE' | translate }}
                                <a (click)="scrollTo('2')">Edit</a>
                            </div>
                            <table class="content-table">
                                <tbody>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.TARIFF.TITLE' | translate }}</b>
                                    </td>
                                    <td>{{ (tariff$ | async).tariff.name }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="overviewPersonalDetails"
                         *ngIf="(personal$ | async)?.personalDetails?.firstName">
                        <div class="data_box">
                            <div class="data_box_header">03 {{ 'REGISTER.PERSONAL-DETAILS.PERSONAL-DATA' | translate }}
                                <a (click)="scrollTo('3')">Edit</a>
                            </div>
                            <table class="content-table">
                                <tbody>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.GENDER' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.gender?.name }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.TITLE' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.title }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.FIRST-NAME' | translate }}</b>
                                    </td>
                                    <td> {{ (personal$ | async).personalDetails.firstName }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.SURNAME' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.surname }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.SUBJECTS' | translate }}</b>
                                    </td>
                                    <td *ngIf="( personal$ | async).personalDetails.subjects">
                                        {{ getCommaSeparated((personal$ | async).personalDetails.subjects)}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.PRACTICE-NAME' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.practiceName }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.STREET' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.street }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.HOUSE-NUMBER' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.houseNumber }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.ADDITION-ADDRESS' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.additionalAddress }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.POSTALCODE' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.zipCode }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.LOCATION' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.location }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.COUNTRY' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.country?.name }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.PERSONAL-DETAILS.TELEPHONE' | translate }}</b>
                                    </td>
                                    <td>{{ (personal$ | async).personalDetails.telephone }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div id="overviewBankDetails"
                         *ngIf="(invoice$ | async)?.owner">
                        <div class="data_box">
                            <div class="data_box_header">04 {{ 'REGISTER.INVOICE.TITLE' | translate }}
                                <a (click)="scrollTo('4')">Edit</a>
                            </div>
                            <table class="content-table">
                                <tbody>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.INVOICE.OWNER' | translate }}</b>
                                    </td>
                                    <td>{{ (invoice$ | async).owner }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.INVOICE.IBAN' | translate }}</b>
                                    </td>
                                    <td>{{ (invoice$ | async).iban }}</td>
                                </tr>
                                <tr *ngIf="(invoice$ | async)?.bic !== defaultBic">
                                    <td>
                                        <b>{{ 'REGISTER.INVOICE.BIC' | translate }}</b>
                                    </td>
                                    <td>{{ (invoice$ | async).bic }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>{{ 'REGISTER.INVOICE.BANK' | translate }}</b>
                                    </td>
                                    <td>{{ (invoice$ | async).bank }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="termsForm" (ngSubmit)="onSubmit()">
            <div class="container footer row">
                <div class="col-md-6">
                    <div class="box_content">
                        <div id="footerRateInfo"></div>
                        <div class="checkbox">
                            <div *ngIf="!termsForm.controls['terms'].valid && termsForm.controls['terms'].touched"
                                 class="error  contract-terms-error">
                                <div *ngIf="termsForm.controls['terms'].errors.required" class="error">
                                    {{'REGISTER.CONTRACT-OVERVIEW.ERROR.TERM-REQUIRED' | translate }}
                                </div>
                            </div>
                            <input formControlName="terms" type="checkbox" name="terms" id="termsAndConditions">
                            <label for="termsAndConditions">{{ 'REGISTER.CONTRACT-OVERVIEW.I-AGGREED' |
                                translate }}
                                <a target="_blank" href="https://www.patientus.de/online-arztvertrag">{{
                                    'REGISTER.CONTRACT-OVERVIEW.ONLINE-DOCTOR-CONTRACT' | translate }}</a>
                                {{ 'REGISTER.CONTRACT-OVERVIEW.READ-APPENDICES' | translate }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="box_content">
                        <ul>
                            <li id="footerRateDetails">
                                <a target="_blank" href="https://www.patientus.de/de/tarifdetails/">{{
                                    'REGISTER.TARIFF.DETAILS' | translate }}</a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.patientus.de/leistungsangebot">{{
                                    'REGISTER.CONTRACT-OVERVIEW.SERVICES' | translate }}</a>
                            </li>
                        </ul>
                        <p>{{ 'REGISTER.CONTRACT-OVERVIEW.NOTING-EMAIl' | translate }}
                        </p>
                        <input id="registrationSubmit"
                               type="submit"
                               [disabled]="(!(account$ | async)?.isValid )
                               || (!(tariff$ | async)?.isValid )
                               || (!(personal$ | async)?.isValid )
                               || (!(invoice$ | async)?.isValid )
                               || (!termsForm.controls['terms'].value)"
                               [ngClass]="{
                               'inactive':(!(account$ | async)?.isValid )
                               || (!(tariff$ | async)?.isValid )
                               || (!(personal$ | async)?.isValid )
                               || (!(invoice$ | async)?.isValid )
                               || (!termsForm.controls['terms'].value)}"
                               value="{{ 'REGISTER.CONTRACT-OVERVIEW.SUBMIT' | translate }}"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
