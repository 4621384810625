<div id="footer">
    <div class="footerLink-dataSecurity-wrapper">
        <div [ngClass]="((brandingData$ | async)?.certificateLink === '') ? 'disable-data-security' : ''">
            <img src="/assets/img/general/patientus_secure.svg" alt="">
            {{ (brandingData$ | async)?.certificateLink === '' ?
            ('FOOTER.NOT-CERTIFIED' | translate) : ('FOOTER.CERTIFIED' | translate) }}
        </div>
    </div>
    <div class="footerLink-wrapper">
        <p>
            <a id="imprintLink" [routerLink]="imprintRoute">
                {{ 'GENERAL.IMPRINT-LINKNAME' | translate }}
            </a>
            -
            <a id="dataSecurityLink" [routerLink]="dataSecurityRoute">
                {{ 'GENERAL.DATA-SECURITY-LINKNAME' | translate }}
            </a>
            -
            {{currentYear}} {{ (brandingData$ | async)?.title ? (brandingData$ | async)?.title : null }}
        </p>
    </div>
    <div class="footer-furtherLink-wrapper">
        <ng-container *ngIf="(brandingData$ | async).lang && (brandingData$ | async).lang.length > 1">
            <app-translation [cssClass]="'footer'"></app-translation>
        </ng-container>
        <ng-container *ngIf="((brandingData$ | async)?.feedbackLink !== '')">
            <a target="_blank"
               href="{{ (brandingData$ | async)?.feedbackLink ? (brandingData$ | async)?.feedbackLink : 'GENERAL.SURVEY-BASE-LINK' | translate }}?id={{ (profileData$ | async)?.id }}&email={{ (profileData$ | async)?.email }}">
                <i class="fal fa-paper-plane"></i>
                {{ 'FOOTER.FEEDBACK' | translate }}
            </a>
        </ng-container>
        <ng-container *ngIf="(brandingData$ | async)?.faqLink !== false">
            <a target="_blank"
               href="{{ 'GENERAL.FAQ-LINK' | translate }}">
                <i class="fal fa-question-circle"></i>
                {{ 'FOOTER.FAQ' | translate }}
            </a>
        </ng-container>
    </div>
</div>
