import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        // NgxPermissionsModule.forChild(),
        TranslateModule.forChild(),
        RouterModule
    ],
    declarations: [DashboardComponent],
    exports: [DashboardComponent]
})
export class DashboardModule {
}
