import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { ModalCloseAction } from './modal.action';
import {
    WAITINGROOM_START_VIDEO_CONFERENCE,
    WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY
} from '../../waitingroom/store/waitingroom.action';

@Injectable()
export class ModalEffects {

    constructor(private actions$: Actions) {
    }

     cleanupModals$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(WAITINGROOM_START_VIDEO_CONFERENCE, WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY),
        map(() => new ModalCloseAction())));
}
