import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Profile } from '../../profile/store/profile.entity';
import { ProfileService } from '../../profile/providers/profile.service';
import { BrandingService } from '../../branding/providers/branding.service';
import { BrandingState } from '../../branding/store/branding.state';

@Component({
    selector: 'app-popup-container',
    templateUrl: './popup-container.component.html',
    styleUrls: ['./popup-container.component.scss']
})
export class PopupContainerComponent {
    @Input() icon: string;
    @Input() showCloseButton = true;
    @Output() closeClicked = new EventEmitter<null>();

    profileData$: Observable<Profile>;
    public brandingData$: Observable<BrandingState>;

    constructor(private profileService: ProfileService, private brandingService: BrandingService) {
        this.profileData$ = this.profileService.getCurrentProfileDataObserver();
        this.brandingData$ = this.brandingService.getBrandingSettingsFromStore().pipe(filter(e => e !== null && e !== undefined));
    }

    closePopup() {
        this.closeClicked.emit();
    }
}
