import { WaitingroomInitialState, WaitingroomState } from './waitingroom.state';
import {
    WAITINGROOM_CLOSE_VIDEO_CONFERENCE,
    WAITINGROOM_PEAR_NOT_READY,
    WAITINGROOM_PEAR_READY,
    WAITINGROOM_RTC_DETECTION_MODAL_MANUALLY_CLOSED,
    WAITINGROOM_START_VIDEO_CONFERENCE,
    WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY,
} from './waitingroom.action';
import { WaitingroomAction } from './waitingroom.action';

export function waitingroomReducer(state: WaitingroomState = new WaitingroomInitialState(), action: WaitingroomAction): WaitingroomState {
    switch (action.type) {
        case WAITINGROOM_PEAR_READY: {
            return {...state, peerReady: true};
        }
        case WAITINGROOM_PEAR_NOT_READY: {
            return {...state, peerReady: false};
        }
        case WAITINGROOM_RTC_DETECTION_MODAL_MANUALLY_CLOSED: {
            return {...state, rtcDetectionModalManuallyClosed: true};
        }
        case WAITINGROOM_START_VIDEO_CONFERENCE_REMOTELY:
        case WAITINGROOM_START_VIDEO_CONFERENCE: {
            return {...state, onCall: true};
        }
        case WAITINGROOM_CLOSE_VIDEO_CONFERENCE: {
            return {...state, onCall: false};
        }
        default: {
            return state;
        }
    }
}
