<div class="overflow-wrapper">
    <div class="announces dashboard">
        <img class="header-illustration" src="/assets/img/illustrations/image-upload-dashboard.png" alt="">
        <div class="content-wrapper">
            <h2>{{ 'DASHBOARD.NEWS.TITLE' | translate}}</h2>
            <br>
            <p>{{ 'DASHBOARD.NEWS.BODY.PART-1' | translate}}</p>
            <p>{{ 'DASHBOARD.NEWS.BODY.PART-2' | translate}}</p>
            <p>{{ 'DASHBOARD.NEWS.BODY.PART-3' | translate}}</p>
        </div>
    </div>
</div>
<div class="dashboard-content">
    <h3>
        {{ 'DASHBOARD.UPDATES.HEADER' | translate}}
    </h3>
    <div class="dashboard-updates">
        <p>
            {{ 'DASHBOARD.UPDATES.BODY' | translate}}
        </p>
    </div>
    <hr>
    <div class="dashboard-feedback">
        <h4>{{ 'DASHBOARD.FEEDBACK.TITLE' | translate}}</h4>
        <p>
            {{ 'DASHBOARD.FEEDBACK.BODY' | translate}}
            <a href="{{ 'GENERAL.SURVEY-BASE-LINK' | translate }}?id={{ (profileData$ | async)?.id }}&email={{ (profileData$ | async)?.email }}"
               target="_blank">{{ 'DASHBOARD.FEEDBACK.URL' | translate}}
                <i class="fa fa-external-link"></i>
            </a>
        </p>
    </div>
</div>
