import { Component, OnDestroy } from '@angular/core';
import { PAYMENT_ROUTE_ACCOUNT, PAYMENT_ROUTE_INFO } from '../../routes';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-payment-page',
    templateUrl: './payment-page.component.html',
    styleUrls: ['./payment-page.component.scss']
})
export class PaymentPageComponent implements OnDestroy {

    private routerSubscription: Subscription = null;
    paymentAccountLink = PAYMENT_ROUTE_ACCOUNT;
    paymentInfoLink = PAYMENT_ROUTE_INFO;

    activeRoute = PAYMENT_ROUTE_ACCOUNT;

    constructor(private router: Router) {
        this.listForPathChanges();
    }

    ngOnDestroy(): void {
        if (this.routerSubscription !== null) {
            this.routerSubscription.unsubscribe();
        }
    }

    isActiveTab(route: string): boolean {
        return (!!this.activeRoute && this.activeRoute.indexOf(route) >= 0);
    }

    private listForPathChanges(): void {
        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.activeRoute = event.urlAfterRedirects;
            }
        });
    }
}
