import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { REGISTER_ROUTE } from '../../../../routes';

@Component({
    selector: 'app-registartion-failed',
    templateUrl: './registartion-failed.component.html',
    styleUrls: ['../../../../info-pages-styles.scss', './registartion-failed.component.scss']
})
export class RegistartionFailedComponent {

    constructor(private translate: TranslateService, private router: Router) {
    }

    public goToRegister(): void {
        this.router.navigate([REGISTER_ROUTE]);
    }
}
