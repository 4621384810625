<ng-template ngxPermissionsOnly="doctor">
    <div id="sidebar" [ngClass]="{
        'patient': (roles$ | async).hasOwnProperty(PATIENT),
        'doctor': (roles$ | async).hasOwnProperty(DOCTOR),
        'collapsed': (remoteVideoVisibility$ | async),
        'hide-side-bar': (hideSideBar$ | async)
        }">
        <div class="sidebar-child menu-element"
             *ngIf="isNotRestricted(DASHBOARD)"
             (click)="changeTab(DASHBOARD)"
             [ngClass]="{'active': isActiveTab(DASHBOARD) && !(remoteVideoVisibility$ | async)}">
                <span>
                    <i class="fa fa-home is-active"></i>
                    <i class="fal fa-home is-not-active"></i>
                    <span class="description">{{ 'SIDEBAR.HOME' | translate }}</span>
                </span>
        </div>
        <div class="sidebar-child menu-element"
             *ngIf="isNotRestricted(WAITINGROOM) && !isMUCEnabled"
             (click)="changeTab(WAITINGROOM)"
             [ngClass]="{'active': isActiveTab(WAITINGROOM) && !(remoteVideoVisibility$ | async)}">
                <span>
                    <i class="fal fa-alarm-clock is-not-active"></i>
                    <i class="fa fa-alarm-clock is-active"></i>
                    <span class="description">{{ 'SIDEBAR.WAITINGROOM' | translate }}</span>
                </span>
        </div>
        <div class="sidebar-child menu-element"
             *ngIf="isNotRestricted(TAN_CREATE)"
             (click)="changeTab(TAN_CREATE)"
             [ngClass]="{'active': isActiveTab(TAN_CREATE) && !(remoteVideoVisibility$ | async)}">
                <span>
                    <i class="fal fa-calendar-edit is-not-active"></i>
                    <i class="fa fa-calendar-edit is-active"></i>
                    <span class="description">{{ 'SIDEBAR.TAN-CREATE' | translate }}</span>
                </span>
        </div>
        <div class="sidebar-child menu-element appointment-overview"
             *ngIf="isNotRestricted(APPOINTMENT_OVERVIEW) && !isMUCEnabled"
             (click)="changeTab(APPOINTMENT_OVERVIEW)"
             [ngClass]="{'active': isActiveTab(APPOINTMENT_OVERVIEW) && !(remoteVideoVisibility$ | async)}">
                <span>
                    <i class="fal fa-calendar-alt is-not-active"></i>
                    <i class="fa fa-calendar-alt is-active"></i>
                    <span class="description">{{ 'SIDEBAR.OVERVIEW' | translate }}</span>
                </span>
            <span class="badge" *ngIf="!hideTabBasedOnInstitution"
                  [ngClass]="{'green':countAppointmentRequests > 0}">{{countAppointmentRequests}}</span>
        </div>
        <div class="sidebar-child menu-element appointment-overview"
             *ngIf="isNotRestricted(APPOINTMENT_OVERVIEW_MULTIUSER) && isMUCEnabled"
             (click)="changeTab(APPOINTMENT_OVERVIEW_MULTIUSER)"
             [ngClass]="{'active': isActiveTab(APPOINTMENT_OVERVIEW_MULTIUSER) && !(remoteVideoVisibility$ | async)}">
                <span>
                    <i class="fal fa-calendar-alt is-not-active"></i>
                    <i class="fa fa-calendar-alt is-active"></i>
                    <span class="description">{{ 'SIDEBAR.OVERVIEW' | translate }}</span>
                </span>
        </div>
        <div class="sidebar-child menu-element appointment-overview"
             *ngIf="isNotRestricted(APPOINTMENT_MULTIUSER_REQUESTS) && isMUCEnabled && !hideTabBasedOnInstitution"
             (click)="changeTab(APPOINTMENT_MULTIUSER_REQUESTS)"
             [ngClass]="{'active': isActiveTab(APPOINTMENT_MULTIUSER_REQUESTS) && !(remoteVideoVisibility$ | async)}">
                <span>
                    <i class="fal fa-download is-not-active"></i>
                    <i class="fa fa-download is-active"></i>
                    <span class="description">{{ 'WAITINGROOM.TAB-REQUESTS' | translate}}</span>
                </span>
            <span class="badge" *ngIf="!hideTabBasedOnInstitution"
                  [ngClass]="{'green':countAppointmentRequests > 0}">{{countAppointmentRequests}}</span>
        </div>
        <div class="sidebar-child menu-element payment" *ngIf="isNotRestricted(PAYMENT)"
             (click)="changeTab(PAYMENT)"
             [ngClass]="{'active': isActiveTab(PAYMENT) && !(remoteVideoVisibility$ | async)}">
                <span>
                    <i class="fal fa-wallet is-not-active"></i>
                    <i class="fa fa-wallet is-active"></i>
                    <span class="description">{{ 'SIDEBAR.PAYMENT' | translate }}
                    </span>
                </span>
        </div>
        <div class="sidebar-child menu-element"
             *ngIf="isNotRestricted(SETTING)"
             (click)="changeTab(SETTING)"
             [ngClass]="{'active': isActiveTab(SETTING)}">
                <span>
                    <i class="fal fa-cog is-not-active"></i>
                    <i class="fa fa-cog is-active"></i>
                    <span class="description">{{ 'SIDEBAR.SETTING' | translate }}</span>
                </span>
        </div>
        <div class="sidebar-child menu-element"
             *ngIf="isNotRestricted(HELP)"
             [ngClass]="{'active': isActiveTab(HELP) && !(remoteVideoVisibility$ | async)}"
             (click)="changeTab(HELP)">
                <span>
                    <i class="fal fa-life-saver is-not-active"></i>
                    <i class="fa fa-life-saver is-active"></i>
                    <span class="description">{{ 'SIDEBAR.HELP' | translate }}</span>
                </span>
        </div>
        <div class="sidebar-child version-element weight-300">
                <span> {{ 'DASHBOARD.UPDATES.LATEST_UPDATE' | translate}}: {{versionUpdatedDate}}
                    Version: {{version}} </span>
        </div>
    </div>
</ng-template>
