import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { modalReducer } from './store/modal.reducer';
import { ModalErrorComponent } from './error/error.component';
import { ModalComponent } from './modal.component';
import { EffectsModule } from '@ngrx/effects';
import { ModalEffects } from './store/modal.effect';
import { ConfirmCloseOverlayComponent } from './confirm-close-overlay/confirm-close-overlay.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ConnectionInterruptedOverlayComponent } from './connection-interrupted-overlay/connection-interrupted-overlay.component';
import { ConnectionStoppedOverlayComponent } from './connection-stopped-overlay/connection-stopped-overlay.component';
import { SurveyOverlayComponent } from './survey-overlay/survey-overlay.component';
import { StartSurveyOverlayComponent } from './survey-overlay/start-survey-overlay/start-survey-overlay.component';
import { FixedBlackBackgroundComponent } from './fixed-black-background/fixed-black-background.component';
import { PopupContainerComponent } from './popup-container/popup-container.component';
import { UtilsModule } from '../utils/utils.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BrandingModule } from '../branding/branding.module';
import { BrowserDetectOverlayComponent } from './browser-detect-overlay/browser-detect-overlay.component';
import { DownloadChromeBrowserComponent } from './browser-detect-overlay/download-chrome-browser/download-chrome-browser.component';
import { UpdateBrowserVersionComponent } from './browser-detect-overlay/update-browser-version/update-browser-version.component';
import { RecommendChromeBrowserComponent } from './browser-detect-overlay/recommend-chrome-browser/recommend-chrome-browser.component';
import {
    SafariSwitchRecommendationComponent
} from './browser-detect-overlay/safari-switch-recommendation/safari-switch-recommendation.component';
import {
    MobileChromeRecommendationComponent
} from './browser-detect-overlay/mobile-chrome-recommendation/mobile-chrome-recommendation.component';
import { OutdatedBrowserVersionComponent } from './browser-detect-overlay/outdated-browser-version/outdated-browser-version.component';
import { SafariNotSupportedComponent } from './browser-detect-overlay/safari-not-supported/safari-not-supported.component';

@NgModule({
    imports: [
        StoreModule.forFeature('modal', modalReducer),
        EffectsModule.forFeature([ModalEffects]),
        TranslateModule.forChild(),
        CommonModule,
        UtilsModule,
        NgxPermissionsModule,
        BrandingModule
    ],
    declarations: [
        ModalComponent,
        ModalErrorComponent,
        ConfirmCloseOverlayComponent,
        ConnectionInterruptedOverlayComponent,
        ConnectionStoppedOverlayComponent,
        SurveyOverlayComponent,
        FixedBlackBackgroundComponent,
        PopupContainerComponent,
        StartSurveyOverlayComponent,
        BrowserDetectOverlayComponent,
        DownloadChromeBrowserComponent,
        UpdateBrowserVersionComponent,
        RecommendChromeBrowserComponent,
        SafariSwitchRecommendationComponent,
        MobileChromeRecommendationComponent,
        OutdatedBrowserVersionComponent,
        SafariNotSupportedComponent
    ],
    providers: [
        NgbActiveModal
    ],
    exports: [
        ModalComponent,
        FixedBlackBackgroundComponent,
        PopupContainerComponent,
        SurveyOverlayComponent
    ],
})
export class ModalModule {
}
