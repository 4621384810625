export const Countries = [
    { id: 2, name: 'Afghanistan' },
    { id: 3, name: 'Ägypten' },
    { id: 4, name: 'Aland' },
    { id: 5, name: 'Albanien' },
    { id: 6, name: 'Algerien' },
    { id: 8, name: 'Amerikanisch-Samoa' },
    { id: 9, name: 'Amerikanische Jungferninseln' },
    { id: 10, name: 'Andorra' },
    { id: 11, name: 'Angola' },
    { id: 12, name: 'Anguilla' },
    { id: 13, name: 'Antarktis' },
    { id: 14, name: 'Antigua und Barbuda' },
    { id: 15, name: 'Äquatorialguinea, Republik' },
    { id: 16, name: 'Argentinien' },
    { id: 17, name: 'Armenien' },
    { id: 18, name: 'Aruba' },
    { id: 20, name: 'Aserbaidschan' },
    { id: 21, name: 'Äthiopien' },
    { id: 22, name: 'Australien' },
    { id: 23, name: 'Bahamas' },
    { id: 24, name: 'Bahrain' },
    { id: 25, name: 'Bangladesch' },
    { id: 26, name: 'Barbados' },
    { id: 27, name: 'Belgien' },
    { id: 28, name: 'Belize' },
    { id: 29, name: 'Benin' },
    { id: 30, name: 'Bermuda' },
    { id: 31, name: 'Bhutan' },
    { id: 32, name: 'Bolivien' },
    { id: 33, name: 'Bosnien und Herzegowina' },
    { id: 34, name: 'Botswana' },
    { id: 35, name: 'Bouvetinsel' },
    { id: 36, name: 'Brasilien' },
    { id: 37, name: 'Britische Jungferninseln' },
    { id: 38, name: 'Britisches Territorium im Indischen Ozean' },
    { id: 39, name: 'Brunei' },
    { id: 40, name: 'Bulgarien' },
    { id: 41, name: 'Burkina Faso' },
    { id: 43, name: 'Burundi' },
    { id: 45, name: 'Chile' },
    { id: 46, name: 'China, Volksrepublik' },
    { id: 48, name: 'Cookinseln' },
    { id: 49, name: 'Costa Rica' },
    { id: 50, name: 'Cote d\'Ivoire' },
    { id: 51, name: 'Dänemark' },
    { id: 52, name: 'Deutschland' },
    { id: 53, name: 'Die Kronkolonie St. Helena und Nebengebiete' },
    { id: 55, name: 'Dominica' },
    { id: 56, name: 'Dominikanische Republik' },
    { id: 57, name: 'Dschibuti' },
    { id: 58, name: 'Ecuador' },
    { id: 59, name: 'El Salvador' },
    { id: 60, name: 'Eritrea' },
    { id: 61, name: 'Estland' },
    { id: 63, name: 'Falklandinseln' },
    { id: 64, name: 'Färöer' },
    { id: 65, name: 'Fidschi' },
    { id: 66, name: 'Finnland' },
    { id: 67, name: 'Frankreich' },
    { id: 68, name: 'Französisch-Guayana' },
    { id: 69, name: 'Französisch-Polynesien' },
    { id: 70, name: 'Französische Süd- und Antarktisgebiete' },
    { id: 71, name: 'Gabun' },
    { id: 72, name: 'Gambia' },
    { id: 73, name: 'Georgien' },
    { id: 74, name: 'Ghana, Republik' },
    { id: 75, name: 'Gibraltar' },
    { id: 76, name: 'Grenada' },
    { id: 77, name: 'Griechenland' },
    { id: 78, name: 'Grönland' },
    { id: 80, name: 'Guam' },
    { id: 81, name: 'Guatemala' },
    { id: 82, name: 'Guernsey, Vogtei' },
    { id: 83, name: 'Guinea, Republik' },
    { id: 84, name: 'Guinea-Bissau, Republik' },
    { id: 85, name: 'Guyana' },
    { id: 86, name: 'Haiti' },
    { id: 87, name: 'Heard und McDonaldinseln' },
    { id: 88, name: 'Honduras' },
    { id: 89, name: 'Hongkong' },
    { id: 90, name: 'Indien' },
    { id: 91, name: 'Indonesien' },
    { id: 92, name: 'Insel Mann' },
    { id: 93, name: 'Irak' },
    { id: 94, name: 'Iran' },
    { id: 95, name: 'Irland, Republik' },
    { id: 96, name: 'Island' },
    { id: 97, name: 'Israel' },
    { id: 98, name: 'Italien' },
    { id: 99, name: 'Jamaika' },
    { id: 100, name: 'Japan' },
    { id: 101, name: 'Jemen' },
    { id: 102, name: 'Jersey' },
    { id: 103, name: 'Jordanien' },
    { id: 105, name: 'Kaimaninseln' },
    { id: 106, name: 'Kambodscha' },
    { id: 107, name: 'Kamerun' },
    { id: 108, name: 'Kanada' },
    { id: 110, name: 'Kap Verde, Republik' },
    { id: 111, name: 'Kasachstan' },
    { id: 112, name: 'Katar' },
    { id: 113, name: 'Kenia' },
    { id: 114, name: 'Kirgisistan' },
    { id: 115, name: 'Kiribati' },
    { id: 116, name: 'Kokosinseln' },
    { id: 117, name: 'Kolumbien' },
    { id: 118, name: 'Komoren' },
    { id: 119, name: 'Kongo, Demokratische Republik' },
    { id: 120, name: 'Kongo, Republik' },
    { id: 121, name: 'Korea, Demokratische Volkrepublik' },
    { id: 122, name: 'Korea, Republik' },
    { id: 123, name: 'Kroatien' },
    { id: 124, name: 'Kuba' },
    { id: 125, name: 'Kuwait' },
    { id: 126, name: 'Laos' },
    { id: 127, name: 'Lesotho' },
    { id: 129, name: 'Lettland' },
    { id: 130, name: 'Libanon' },
    { id: 131, name: 'Liberia, Republik' },
    { id: 132, name: 'Libyen' },
    { id: 133, name: 'Liechtenstein, Fürstentum' },
    { id: 134, name: 'Litauen' },
    { id: 135, name: 'Luxemburg' },
    { id: 136, name: 'Macao' },
    { id: 137, name: 'Madagaskar, Republik' },
    { id: 138, name: 'Malawi, Republik' },
    { id: 139, name: 'Malaysia' },
    { id: 140, name: 'Malediven' },
    { id: 141, name: 'Mali, Republik' },
    { id: 142, name: 'Malta' },
    { id: 143, name: 'Marokko' },
    { id: 144, name: 'Marshallinseln' },
    { id: 145, name: 'Martinique' },
    { id: 146, name: 'Mauretanien' },
    { id: 147, name: 'Mauritius, Republik' },
    { id: 148, name: 'Mayotte' },
    { id: 149, name: 'Mazedonien' },
    { id: 150, name: 'Mexiko' },
    { id: 151, name: 'Mikronesien, Föderierte Staaten von' },
    { id: 152, name: 'Moldawien' },
    { id: 153, name: 'Monaco' },
    { id: 154, name: 'Mongolei' },
    { id: 155, name: 'Montserrat' },
    { id: 156, name: 'Mosambik' },
    { id: 157, name: 'Myanmar' },
    { id: 158, name: 'Namibia, Republik' },
    { id: 159, name: 'Nauru' },
    { id: 160, name: 'Nepal' },
    { id: 161, name: 'Neukaledonien' },
    { id: 162, name: 'Neuseeland' },
    { id: 164, name: 'Nicaragua' },
    { id: 165, name: 'Niederlande' },
    { id: 166, name: 'Niederländische Antillen' },
    { id: 167, name: 'Niger' },
    { id: 168, name: 'Nigeria' },
    { id: 169, name: 'Niue' },
    { id: 170, name: 'Nördliche Marianen' },
    { id: 171, name: 'Norfolkinsel' },
    { id: 172, name: 'Norwegen' },
    { id: 173, name: 'Oman' },
    { id: 174, name: 'Österreich' },
    { id: 175, name: 'Pakistan' },
    { id: 176, name: 'Palästinensische Autonomiegebiete' },
    { id: 177, name: 'Palau' },
    { id: 178, name: 'Panama' },
    { id: 179, name: 'Papua-Neuguinea' },
    { id: 180, name: 'Paraguay' },
    { id: 181, name: 'Peru' },
    { id: 182, name: 'Philippinen' },
    { id: 183, name: 'Pitcairninseln' },
    { id: 184, name: 'Polen' },
    { id: 185, name: 'Portugal' },
    { id: 186, name: 'Puerto Rico' },
    { id: 187, name: 'Réunion' },
    { id: 188, name: 'Ruanda, Republik' },
    { id: 189, name: 'Rumänien' },
    { id: 190, name: 'Russische Föderation' },
    { id: 191, name: 'Salomonen' },
    { id: 192, name: 'Sambia, Republik' },
    { id: 193, name: 'Samoa' },
    { id: 194, name: 'San Marino' },
    { id: 195, name: 'São Tomé und Príncipe' },
    { id: 196, name: 'Saudi-Arabien, Königreich' },
    { id: 197, name: 'Schweden' },
    { id: 198, name: 'Schweiz' },
    { id: 199, name: 'Senegal' },
    { id: 201, name: 'Seychellen, Republik der' },
    { id: 202, name: 'Sierra Leone, Republik' },
    { id: 203, name: 'Simbabwe, Republik' },
    { id: 204, name: 'Singapur' },
    { id: 205, name: 'Slowakei' },
    { id: 206, name: 'Slowenien' },
    { id: 207, name: 'Somalia, Demokratische Republik' },
    { id: 208, name: 'Spanien' },
    { id: 209, name: 'Sri Lanka' },
    { id: 210, name: 'St. Kitts und Nevis' },
    { id: 211, name: 'St. Lucia' },
    { id: 212, name: 'St. Pierre und Miquelon' },
    { id: 213, name: 'St. Vincent und die Grenadinen (GB)' },
    { id: 214, name: 'Südafrika, Republik' },
    { id: 215, name: 'Sudan' },
    { id: 216, name: 'Südgeorgien und die Südlichen Sandwichinseln' },
    { id: 217, name: 'Sur, name' },
    { id: 218, name: 'Svalbard und Jan Mayen' },
    { id: 219, name: 'Swasiland' },
    { id: 220, name: 'Syrien' },
    { id: 221, name: 'Tadschikistan' },
    { id: 222, name: 'Taiwan' },
    { id: 223, name: 'Tansania, Vereinigte Republik' },
    { id: 224, name: 'Thailand' },
    { id: 225, name: 'Timor-Leste, Demokratische Republik' },
    { id: 226, name: 'Togo, Republik' },
    { id: 227, name: 'Tokelau' },
    { id: 228, name: 'Tonga' },
    { id: 229, name: 'Trinidad und Tobago' },
    { id: 231, name: 'Tschad, Republik' },
    { id: 232, name: 'Tschechische Republik' },
    { id: 234, name: 'Tunesien' },
    { id: 235, name: 'Türkei' },
    { id: 236, name: 'Turkmenistan' },
    { id: 237, name: 'Turks- und Caicosinseln' },
    { id: 238, name: 'Tuvalu' },
    { id: 239, name: 'Uganda, Republik' },
    { id: 240, name: 'Ukraine' },
    { id: 258, name: 'Ungarn' },
    { id: 242, name: 'Uruguay' },
    { id: 243, name: 'Usbekistan' },
    { id: 244, name: 'Vanuatu' },
    { id: 245, name: 'Vatikanstadt' },
    { id: 246, name: 'Venezuela' },
    { id: 247, name: 'Vereinigte Arabische Emirate' },
    { id: 248, name: 'Vereinigte Staaten von Amerika' },
    { id: 249, name: 'Vereinigtes Königreich von Großbritannien und Nordirland' },
    { id: 250, name: 'Vietnam' },
    { id: 251, name: 'Wallis und Futuna' },
    { id: 252, name: 'Weihnachtsinsel' },
    { id: 253, name: 'Weißrussland' },
    { id: 254, name: 'Westsahara' },
    { id: 256, name: 'Zentralafrikanische Republik' },
    { id: 257, name: 'Zypern, Republik' },
];

export class Country {
    id: number;
    name: string;
}
