<div class="fixed-black-background"></div>
<div class="popup-container-wrapper">
    <div class="popup-container">
        <div class="popup-header">
            <h4>
                <span [innerHTML]="icon"></span>
                {{ 'GENERAL.SURVEY-TITLE' | translate }}
            </h4>
            <button type="button"
                    class="close"
                    aria-label="Close"
                    (click)="closePopup()"
                    *ngIf="showCloseButton">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
        </div>
        <div class="popup-body">
            <ng-content></ng-content>
        </div>
    </div>
</div>
