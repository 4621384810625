<div id="step4" class="container">
    <form [formGroup]="invoiceForm" (ngSubmit)="onSubmit()">
        <h2><span>04</span> {{ 'REGISTER.INVOICE.TITLE' | translate}}</h2>
        <div class="box1">
            <div class="box_content">
                <!--OWNER SECTION----------------------------------------------------------------------------------------->
                <div *ngIf="!invoiceForm.controls['owner'].valid && invoiceForm.controls['owner'].touched"
                     class="error">
                    <div *ngIf="invoiceForm.controls['owner'].errors.required" class="error">
                        {{'REGISTER.INVOICE.ERROR.REQUIRED' | translate }}
                    </div>
                </div>
                <input class="validate summary_hidden"
                       name="owner"
                       formControlName="owner"
                       type="text"
                       value="" placeholder="{{ 'REGISTER.INVOICE.OWNER' | translate}}">
                <!--IBAN SECTION----------------------------------------------------------------------------------------->
                <div *ngIf="!invoiceForm.controls['iban'].valid && invoiceForm.controls['iban'].touched" class="error">
                    <div *ngIf="invoiceForm.controls['iban'].errors.required" class="error">
                        {{'REGISTER.INVOICE.ERROR.REQUIRED' | translate }}
                    </div>
                    <div *ngIf="invoiceForm.controls['iban'].errors.iban" class="error">
                        {{'REGISTER.INVOICE.ERROR.IBAN_INVALID' | translate }}
                    </div>
                </div>
                <input class="validate summary_hidden"
                       name="iban"
                       formControlName="iban"
                       type="text"
                       value="" placeholder="{{ 'REGISTER.INVOICE.IBAN' | translate}}">
                <!--BIC SECTION-------------------------------------------------------------------------------------------->
                <div *ngIf="!invoiceForm.controls['bic'].valid && invoiceForm.controls['bic'].touched" class="error">
                    <div *ngIf="invoiceForm.controls['bic'].errors.required" class="error">
                        {{'REGISTER.INVOICE.ERROR.REQUIRED' | translate }}
                    </div>
                    <div
                        *ngIf="invoiceForm.controls['bic'].errors.minlength || invoiceForm.controls['bic'].errors.maxlength"
                        class="error">
                        {{'REGISTER.INVOICE.ERROR.BIC_INVALID' | translate }}
                    </div>
                </div>
                <input class="validate summary_hidden"
                       [hidden]="shouldHideBic"
                       name="bic"
                       formControlName="bic"
                       type="text"
                       value="" placeholder="{{ 'REGISTER.INVOICE.BIC' | translate}}">
                <!--BANK SECTION-------------------------------------------------------------------------------------------->
                <div *ngIf="!invoiceForm.controls['bank'].valid && invoiceForm.controls['bank'].touched" class="error">
                    <div *ngIf="invoiceForm.controls['bank'].errors.required" class="error">
                        {{'REGISTER.INVOICE.ERROR.REQUIRED' | translate }}
                    </div>
                </div>
                <input class="validate summary_hidden"
                       name="bank"
                       formControlName="bank"
                       type="text"
                       value="" placeholder="{{ 'REGISTER.INVOICE.BANK' | translate}}">
            </div>
        </div>
        <div class="box1">
            <div class="box_content">
                <h4>{{ 'REGISTER.INVOICE.SUMMARY.TITLE' | translate}}</h4>
                <div *ngIf="!invoiceForm.controls['terms'].valid && invoiceForm.controls['terms'].touched"
                     class="error">
                    <div *ngIf="invoiceForm.controls['terms'].errors.required" class="error">
                        {{'REGISTER.INVOICE.ERROR.REQUIRED' | translate }}
                    </div>
                </div>
                <div class="checkbox">
                    <input type="checkbox" formControlName="terms" name="terms" class="summary_hidden" id="checkbox4">
                    <label for="checkbox4" [innerHTML]="'REGISTER.INVOICE.SUMMARY.BODY' | translate"></label>
                </div>
                <input [ngClass]=" {'inactive':!invoiceForm.valid }"
                       data-step="6"
                       type="submit"
                       id="submitInvoice"
                       value="{{ 'REGISTER.GENERAL.NEXT_STEP' | translate }}">
            </div>
        </div>
    </form>
</div>
