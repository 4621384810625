import { filter, take } from 'rxjs/operators';
import { Component } from '@angular/core';
import { ProfileService } from '../profile/providers/profile.service';
import { Observable } from 'rxjs';
import { Profile } from '../profile/store/profile.entity';
import { NgxPermissionsService } from 'ngx-permissions';
import { PatientusRole } from '../auth/auth.roles';
import { WAITINGROOM_ROUTE } from '../../routes';
import { RouterHelperService } from '../utils/router-helper/router-helper.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
    public profileData$: Observable<Profile>;

    constructor(private profileService: ProfileService,
                private permissionsService: NgxPermissionsService,
                private router: RouterHelperService) {
        this.profileData$ = this.profileService.getCurrentProfileDataObserver();
        this.getCurrentRole();
    }

    private getCurrentRole(): void {
        this.permissionsService.permissions$.pipe(filter(p => Object.keys(p).length > 0),
            take(1)
        ).subscribe(p => {
                if (p[PatientusRole.PATIENT]) {
                    this.router.navigate([WAITINGROOM_ROUTE]);
                }
            }
        );
    }
}
