<app-fixed-black-background *ngIf="isOverlayVisible === true">

    <div class="text-wrapper">
        <h5>{{ 'MODAL.CLOSE-CONFERENCE.CLOSE-CONFIRM-MESSAGE' | translate }}</h5>
        <div class="flex center">
            <button class="button underlined no-wrap"
                    (click)="cancelClicked()">{{ 'MODAL.CLOSE-CONFERENCE.CANCEL' | translate}}
            </button>
            <button class="button outlined"
                    (click)="closeCallClicked()">{{ 'MODAL.CLOSE-CONFERENCE.AGREE-CLOSE' | translate }}
            </button>
        </div>
    </div>

</app-fixed-black-background>