<div class="header_navigation">
    <a href="https://www.patientus.de/" class="logo"></a>
    <ul id="ul-nav" #navlist>
        <li>
            <a class="active" id="nav1" (click)="scrollTo('1')" href="javascript:;">01 <span class="mobile_invisible">
                {{ 'REGISTRATION.NAVIGATION.REGISTRATION' | translate }}</span></a>
        </li>
        <li>
            <a class="inactive" id="nav2" (click)="scrollTo('2')" href="javascript:;">02 <span
                class="hidden">{{ 'REGISTRATION.NAVIGATION.RATE' | translate }}</span></a>
        </li>
        <li>
            <a class="inactive" id="nav3" data-text="Persönliche Daten" (click)="scrollTo('3')" href="javascript:;">03
                <span class="hidden">{{ 'REGISTRATION.NAVIGATION.PERSONAL-DATA' | translate }}</span></a>
        </li>
        <li>
            <a class="inactive" id="nav4" data-text="Rechnungsdaten" (click)="scrollTo('4')" href="javascript:;">04
                <span class="hidden">{{ 'REGISTRATION.NAVIGATION.BILLING' | translate }}</span></a>
        </li>
        <li>
            <a class="inactive" id="nav5" data-text="Vertragsabschluss" (click)="scrollTo('5')" href="javascript:;">05
                <span class="hidden">{{ 'REGISTRATION.NAVIGATION.CONTRACT' | translate }}</span></a>
        </li>
    </ul>
</div>
