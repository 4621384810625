import {Action} from '@ngrx/store';

export const HEADER_SET_REMOTE_VISIBILITY = 'HEADER_SET_REMOTE_VISIBILITY';
export const HEADER_HIDE_LOGO = 'HEADER_HIDE_LOGO';

export interface HeaderAction extends Action {
    payload: boolean;
}

export class HeaderSetRemoteVisibilityAction implements HeaderAction {
    readonly type = HEADER_SET_REMOTE_VISIBILITY;

    constructor(public payload) {
    }
}

export class HeaderHideLogoAction implements HeaderAction {
    readonly type = HEADER_HIDE_LOGO;

    constructor(public payload) {
    }
}
