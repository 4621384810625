import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tariff-details',
    templateUrl: './tariff-details.component.html',
    styleUrls: ['./tariff-details.component.scss']
})
export class TariffDetailsComponent {

    @Input() public details: any;
    public visible = false;

    close() {
        this.visible = false;
    }

    open() {
        this.visible = true;
    }
}
