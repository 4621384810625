<i class="fal fa-globe"
   [ngClass]="cssClass === 'header' ? 'header-icon' : 'footer-icon'">
</i>
<select
    id="languageSelect"
    placement="bottom"
    triggers="manual"
    [ngClass]="cssClass === 'header' ? 'header-section' : 'footer-section'"
    [(ngModel)]="languageSelected"
    (change)="selectLanguage()">
    <option
        class="options-color"
        [ngValue]="language"
        *ngFor="let language of (brandingSettings$ | async)">
        {{ language }}
    </option>
</select>
