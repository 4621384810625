<div class="tab-list-header">
    <div class="content-wrapper">
        <div class="tab-list-item active"
             [routerLink]="[profileLink]"
             [ngClass]="{'active': isActiveTab(PROFILE)}">
            <span>{{ 'SETTING.PROFILE' | translate}}</span>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
