import {AppState as RootAppState} from '../../../providers/store/app.state';

export interface SidebarState {
    remoteVideoVisible: boolean;
    hideSideBar: boolean;
}

export interface AppState extends RootAppState {
    sidebar: SidebarState;
}

export class SidebarInitialState implements SidebarState {
    remoteVideoVisible = false;
    hideSideBar: false;
}
