<app-fixed-black-background>
    <div class="text-wrapper">
        <div class="flex center icon-display">
            <i class="fal fa-exclamation-triangle fa-5x"></i>
        </div>
        <div class="flex center">
            <h5>{{ 'MODAL.BROWSER-DETECT-OVERLAY.UPDATE-BROWSER-VERSION.TITLE' | translate }}</h5>
        </div>
        <div class="flex center">
            <p class="description">
                {{ 'MODAL.BROWSER-DETECT-OVERLAY.UPDATE-BROWSER-VERSION.DESCRIPTION' | translate }}
                <a class="download-link" target="_blank" href="{{ downloadChromeBrowser }}">
                    {{ 'MODAL.BROWSER-DETECT-OVERLAY.UPDATE-BROWSER-VERSION.DOWNLOAD-CHROME-BROWSER' | translate }}
                </a>
            </p>
        </div>
        <div class="flex center">
            <button class="button underlined" (click)="continueAnyway()">
                {{ 'MODAL.BROWSER-DETECT-OVERLAY.UPDATE-BROWSER-VERSION.BUTTON.CLOSE' | translate }}
            </button>
            <button class="button outlined" (click)="downloadGoogleChrome()">
                {{ ('MODAL.BROWSER-DETECT-OVERLAY.UPDATE-BROWSER-VERSION.BUTTON.OKAY' | translate) }}
            </button>
        </div>
    </div>
</app-fixed-black-background>
