import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CustomValidators} from '../../../forms/validators/custom-validator';

export const preselectedCountry = {id: 52, name: 'Deutschland'};

export class StepPersonalFormBuilder {

    public static build(formBuilder: UntypedFormBuilder): UntypedFormGroup {
        return formBuilder.group({
            title: formBuilder.control(''),
            gender: formBuilder.control(''),
            firstName: formBuilder.control('', Validators.compose([Validators.required])),
            surname: formBuilder.control('', Validators.compose([Validators.required])),
            subjects: formBuilder.control([], Validators.compose([Validators.required])),
            practiceName: formBuilder.control(''),
            street: formBuilder.control('', Validators.compose([Validators.required])),
            houseNumber: formBuilder.control('', Validators.compose([Validators.required])),
            additionalAddress: formBuilder.control(''),
            zipCode: formBuilder.control('', Validators.compose([Validators.required])),
            location: formBuilder.control('', Validators.compose([Validators.required])),
            country: formBuilder.control(preselectedCountry, Validators.compose([Validators.required])),
            telephone: formBuilder.control('', Validators.compose([
                Validators.required,
                CustomValidators.phone
            ])),
        });
    }
}
