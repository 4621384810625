import { filter } from 'rxjs/operators';
import { Component } from '@angular/core';
import { ProfileService } from '../profile/providers/profile.service';
import { Observable } from 'rxjs';
import { Profile } from '../profile/store/profile.entity';
import { TranslateService } from '@ngx-translate/core';
import { BrandingService } from '../branding/providers/branding.service';
import { BrandingState } from '../branding/store/branding.state';
import { LEGAL_DATA_SECURITY, LEGAL_IMPRINT } from '../../routes';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    public brandingData$: Observable<BrandingState>;
    public profileData$: Observable<Profile>;
    public currentYear: number;
    dataSecurityRoute = LEGAL_DATA_SECURITY;
    imprintRoute = LEGAL_IMPRINT;

    constructor(private profileService: ProfileService,
                private translate: TranslateService,
                private brandingService: BrandingService) {
        this.profileData$ = this.profileService.getCurrentProfileDataObserver().pipe(filter(e => e !== null && e !== undefined));
        this.brandingData$ = this.brandingService.getBrandingSettingsFromStore().pipe(filter(e => e !== null && e !== undefined));
        this.currentYear = (new Date()).getFullYear();
    }
}
