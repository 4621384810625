<div>
    <div class="modal-header">
        <h4 class="modal-title">There are some errors!</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5> Error occurred, please try again!</h5>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="closeModal()">Close</button>
    </div>
</div>
