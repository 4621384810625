<ng-template [ngIf]="isCurrentRole(ROLE_DOCTOR)">
    <div class="tab-list-header">
        <div class="content-wrapper">
            <div class="tab-list-item active"
                 (click)="changeActiveTab(UPCOMING)"
                 [ngClass]="{'active': isActiveTab(UPCOMING)}">
                <span>{{ 'WAITINGROOM.TAB-UPCOMING' | translate}}</span>
            </div>
            <div class="tab-list-item"
                 (click)="changeActiveTab(HISTORY)"
                 [ngClass]="{'active': isActiveTab(HISTORY)}">
                <span>{{ 'WAITINGROOM.TAB-HISTORY' | translate}}</span>
            </div>
        </div>
    </div>
    <div class="dashboard-container">
        <div class="container-fluid">
            <div class="preloader-container" *ngIf="(isInit$ | async)">
                <h3 class="preloader-container-message">
                    {{ 'WAITINGROOM.APPOINTMENTS-LOADING' | translate }}
                </h3>
            </div>
        </div>
    </div>
    <div *ngIf="isActiveTab(UPCOMING)">
        <div class="empty-appointments content-wrapper"
             *ngIf="hasNoAppointments((upComingAppointments$ | async)?.length, (isInit$ | async))">
            <h3 class="no-appointment-message">{{ 'WAITINGROOM.EMPTY-APPOINTMENTS-UPCOMING' | translate }}</h3>
        </div>
        <div class="appointment-info-wrapper"
             *ngIf="(upComingAppointments$ | async)?.length>0">
            <app-appointment-info
                *ngFor='let appointment of (upComingAppointments$ | async)'
                [appointment]="appointment"
                [appointmentInfoType]="WAITINGROOM_APPOINTMENT_INFO_TYPE">
            </app-appointment-info>
        </div>
    </div>
    <div *ngIf="isActiveTab(HISTORY)">
        <div class="empty-appointments content-wrapper"
             *ngIf="hasNoAppointments((historyAppointments$ | async)?.length, (isInit$ | async))">
            <h3 class="no-appointment-message">{{ 'WAITINGROOM.EMPTY-APPOINTMENTS-HISTORY' | translate }}</h3>
        </div>
        <div class="appointment-info-wrapper"
             *ngIf="(historyAppointments$ | async)?.length>0">
            <app-appointment-info
                *ngFor='let appointment of (historyAppointments$ | async)'
                [appointment]="appointment"
                [appointmentInfoType]="HISTORY_APPOINTMENT_INFO_TYPE">
            </app-appointment-info>
        </div>
    </div>
    <app-jitsi-settings-pop-up [buttonType]="'none'"></app-jitsi-settings-pop-up>
</ng-template>

<ng-template [ngIf]="isCurrentRole(ROLE_PATIENT)">
    <div class="background-wrapper-image"
         [ngStyle]="{backgroundImage: 'url(' + (backgroundImg$ | async).backgroundImage + ')'}">
        <div class="appointment-info-for-patients-wrapper"
             *ngIf="(appointments$ | async)?.length>0  && !(isRemoteStreamActive$ | async)">
            <app-appointment-info-for-patients
                *ngFor='let appointment of (appointments$ | async)'
                [appointment]="appointment">
            </app-appointment-info-for-patients>
        </div>
    </div>
</ng-template>
