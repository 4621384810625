export interface Tariff {
    tariffId: number;
    name: string;
    price: number;
    currency: string;
    description: string;
    details: TariffDetail[];
    detailsvisible: boolean;
    duration: string;
}


export interface TariffDetail {
    icon: string;
    heading: string;
    text: string;
}


export const Tariffs = [
    {
        tariffId: 1,
        name: 'standard',
        duration: 'Mindestlaufzeit 12 Monate',
        price: 59,
        currency: '€',
        description: '',
        details: [
            {icon: 'check', heading: 'Mindestvertragslaufzeit', text: '12 Monate'},
            {icon: 'check', heading: 'Patientus Arzt-Profil', text: 'alle Funktionen'},
            {icon: 'check', heading: 'keine Provisionen', text: 'Sie erhalten 100% Ihres Umsatzes'},
            {icon: 'check', heading: 'Uneingeschränkte Nutzung', text: 'volle Benutzerkontrolle durch den Arzt'},
            {icon: 'check', heading: 'Kostenlos Termine vergeben', text: 'www.patientus.de & über Ihre Webseite'}
        ],
        detailsvisible: false
    },
    {
        tariffId: 2,
        name: 'professional',
        duration: 'Mindestlaufzeit 12 Monate',
        price: 69,
        currency: '€',
        description: '',
        details: [
            {icon: 'check', heading: 'Mindestvertragslaufzeit', text: '12 Monate'},
            {icon: 'check', heading: 'Alle Funktionen', text: 'von Tarif STANDARD inklusive'},
            {icon: 'plus', heading: 'Vor Ort Terminvergabe', text: 'für Patientenbesuche in der Praxis'},
            {icon: 'plus', heading: 'Telefonischer Support', text: 'Werktags von 9.00 Uhr bis 18.00 Uhr'},
            {icon: 'plus', heading: 'Persönliche Einweisung', text: '(inklusive)'}
        ],
        detailsvisible: false
    }
];
