<div id="step1" class="container">
    <form id="account" [formGroup]="accountForm" (ngSubmit)="onSubmit()" novalidate>
        <h2><span>01</span> {{ 'REGISTER.ACCOUNT.TITLE' | translate }}</h2>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="box_content">
                    <ul>
                        <li>{{ 'REGISTER.ACCOUNT.DESCRIPTION_1' | translate }}</li>
                        <li>{{ 'REGISTER.ACCOUNT.DESCRIPTION_2' | translate }}</li>
                        <li>{{ 'REGISTER.ACCOUNT.DESCRIPTION_3' | translate }}</li>
                    </ul>
                    <!--Email --------------------------------------------------------------------------------------------->
                    <div *ngIf="!accountForm.controls['email'].valid && accountForm.controls['email'].touched"
                         class="error">
                        <div *ngIf="accountForm.controls['email'].errors.required" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.EMAIL.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="accountForm.controls['email'].errors.email" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.EMAIL.FORMAT' | translate }}
                        </div>
                    </div>
                    <input class="validate summary_hidden"
                           formControlName="email"
                           type="text"
                           placeholder="{{ 'REGISTER.ACCOUNT.ACCOUNT_ID' | translate }}"
                           oncopy="return false;"
                           onpaste="return false;"
                           oncut="return false;"
                           autocomplete="off">
                    <!--Confirm Email ------------------------------------------------------------------------------------->
                    <div
                        *ngIf="!accountForm.controls['confirmEmail'].valid && accountForm.controls['confirmEmail'].touched"
                        class="error">
                        <div *ngIf="accountForm.controls['confirmEmail'].errors.required" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.CONFIRM_EMAIL.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="accountForm.controls['confirmEmail'].errors.confirm" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.CONFIRM_EMAIL.CONFIRM' | translate }}
                        </div>
                    </div>
                    <input class="validate summary_hidden"
                           formControlName="confirmEmail"
                           type="text"
                           placeholder="{{ 'REGISTER.ACCOUNT.CONFIRM_ACCOUNT_ID' | translate }}"
                           oncopy="return false;"
                           onpaste="return false;"
                           oncut="return false;"
                           autocomplete="off">
                    <!--Password  ----------------------------------------------------------------------------------------->
                    <div *ngIf="!accountForm.controls['password'].valid && accountForm.controls['password'].touched"
                         class="error">
                        <div *ngIf="accountForm.controls['password'].errors.required" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.PASSWORD.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="accountForm.controls['password'].errors.minlength" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.PASSWORD.MIN' | translate }}
                        </div>
                        <div *ngIf="accountForm.controls['password'].errors.maxlength" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.PASSWORD.MAX' | translate }}
                        </div>
                        <div *ngIf="accountForm.controls['password'].errors.password" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.PASSWORD.FORMAT' | translate }}
                        </div>
                    </div>
                    <input class="validate summary_hidden"
                           formControlName="password"
                           type="password"
                           id="password"
                           placeholder="{{ 'REGISTER.ACCOUNT.PASSWORD' | translate }}"
                           oncopy="return false;" onpaste="return false;" oncut="return false;" autocomplete="off">
                    <div class="box_info">
                        {{'REGISTER.ACCOUNT.PASS_CONSTRAINS' | translate }}
                    </div>
                    <!--Confirm Password  --------------------------------------------------------------------------------->
                    <div
                        *ngIf="!accountForm.controls['confirmPassword'].valid && accountForm.controls['confirmPassword'].touched"
                        class="error">
                        <div *ngIf="accountForm.controls['confirmPassword'].errors.required" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.CONFIRM_PASSWORD.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="accountForm.controls['confirmPassword'].errors.confirm" class="error">
                            {{'REGISTER.ACCOUNT.ERROR.CONFIRM_PASSWORD.CONFIRM' | translate }}
                        </div>
                    </div>
                    <input class="validate summary_hidden"
                           formControlName="confirmPassword"
                           type="password"
                           placeholder="{{ 'REGISTER.ACCOUNT.CONFIRM_PASSWORD' | translate }}">
                    <!--Terms  -------------------------------------------------------------------------------------------->
                    <div class="checkbox">
                        <div *ngIf="!accountForm.controls['terms'].valid && accountForm.controls['terms'].touched"
                             class="error">
                            <div *ngIf="accountForm.controls['terms'].errors.required" class="error">
                                {{'REGISTER.ACCOUNT.ERROR.TERMS.CONFIRM' | translate }}
                            </div>
                        </div>
                        <input type="checkbox" formControlName="terms" id="checkbox-terms" class="summary_hidden">
                        <label for="checkbox-terms">
                            {{'REGISTER.ACCOUNT.PRIVACY_RULES_AGREEMENT' | translate }}
                            <a target="_blank" href="https://www.patientus.de/datenschutz/">
                                {{'REGISTER.ACCOUNT.PRIVACY_RULES' | translate }}
                            </a>
                            {{ 'REGISTER.ACCOUNT.PRIVACY_RULES_AGREEMENT_TO' | translate }}
                        </label>
                    </div>
                    <!--Submit  ------------------------------------------------------------------------------------------->
                    <input class="inactive" data-step="2" type="submit" id="accountSubmit"
                           value="{{ 'REGISTER.GENERAL.NEXT_STEP' | translate }}"
                           [ngClass]="{'inactive' : !accountForm.valid}">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="box_content">
                    <img class="mobile_invisible" src="/assets/img/register/gutschein_code_video.jpg">
                </div>
            </div>
        </div>
    </form>
</div>
