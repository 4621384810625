import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { translateReducer } from './store/translate.reducer';
import { TranslationComponent } from './component/translation.component';
import { FormsModule } from '@angular/forms';

export function translateStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: [{translate: ['defaultLanguage']}], rehydrate: true})(reducer);
}

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('translate', translateReducer),
        FormsModule
    ],
    declarations: [
        TranslationComponent
    ],
    exports: [
        TranslationComponent
    ],
    providers: []
})
export class TranslateModule {
}
