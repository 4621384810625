import { Component, OnDestroy, OnInit } from '@angular/core';
import { detect } from 'detect-browser';
import { AppState, ModalCategory } from '../store/modal.state';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngrx/store';
import { ModalOpenBrowserDetectionAction } from '../store/modal.action';
import { StaticUtils } from '../../utils/static-utils';

enum Windows {
    WINDOWS_3_11 = 'Windows 3.11',
    WINDOWS_95 = 'Windows 95',
    WINDOWS_98 = 'Windows 98',
    WINDOWS_2000 = 'Windows 2000',
    WINDOWS_XP = 'Windows XP',
    WINDOWS_SERVER_2003 = 'Windows Server 2003',
    WINDOWS_VISTA = 'Windows Vista',
    WINDOWS_7 = 'Windows 7',
    WINDOWS_8 = 'Windows 8',
    WINDOWS_8_1 = 'Windows 8.1',
    WINDOWS_10 = 'Windows 10',
    WINDOWS_NT_10 = 'Windows NT 10.0',
    WINDOWS_ME = 'Windows ME'
}

enum Os {
    IOS = 'iOS',
    ANDROID = 'Android OS',
    MAC = 'Mac OS'
}

enum Browser {
    CHROME = 'chrome',
    FIREFOX = 'firefox',
    SAFARI = 'safari',
    EDGE = 'edge',
    EDGE_CHROMIUM = 'edge-chromium'
}

enum SupportedBrowserVersion {
    CHROME = '83.0',
    SAFARI = '13.1',
    SAFARI_MOBILE = '14.0.2',
    FIREFOX = '76.0',
    FIREFOX_ESR = '68.8',
    EDGE = '79.0'
}

enum UnSupportedSafariMobileList {
    '14.0',
    '14.0.1'
}

const isSafariMobileUnsupported = (browserVer: string) => Object.keys(UnSupportedSafariMobileList).some(version => (
    StaticUtils.compareVersionNumbers(browserVer, version) === 0
));

@Component({
    selector: 'app-browser-detect-overlay',
    templateUrl: './browser-detect-overlay.component.html',
    styleUrls: ['./browser-detect-overlay.component.scss']
})
export class BrowserDetectOverlayComponent implements OnInit, OnDestroy {
    public isOverlayVisible: boolean;

    public showDownloadChromeBrowser = false;
    public showRecommendChromeBrowser = false;
    public showUpdateBrowserVersion = false;
    public showSafariSwitchRecommendation = false;
    public showGoogleChromeMobileRecommendation = false;
    public showOutdatedBrowserForMacOs = false;
    public showSafariNotSupported = false;

    private readonly storeSubscription: Subscription;
    private isChromeSupported: boolean;
    private isFirefoxSupported: boolean;
    private isFirefoxEsrSupported: boolean;
    private isSafariSupported: boolean;
    private isSafariMobileSupported: boolean;
    private isEdgeSupported: boolean;

    constructor(private store: Store<AppState>) {
        this.storeSubscription = this.store.select(s => s.modal).subscribe(modalStore => {
                this.isOverlayVisible = (modalStore.isActive && (modalStore.category === ModalCategory.BROWSER_DETECTION));
            }
        );
    }

    ngOnInit() {
        const browser = detect();
        this.isChromeSupported = StaticUtils.compareVersionNumbers(browser.version, SupportedBrowserVersion.CHROME) >= 0;
        this.isFirefoxSupported = StaticUtils.compareVersionNumbers(browser.version, SupportedBrowserVersion.FIREFOX) >= 0;
        this.isFirefoxEsrSupported = StaticUtils.compareVersionNumbers(browser.version, SupportedBrowserVersion.FIREFOX_ESR) >= 0;
        this.isSafariSupported = StaticUtils.compareVersionNumbers(browser.version, SupportedBrowserVersion.SAFARI) >= 0;
        this.isSafariMobileSupported = StaticUtils.compareVersionNumbers(browser.version, SupportedBrowserVersion.SAFARI_MOBILE) >= 0;
        this.isEdgeSupported = StaticUtils.compareVersionNumbers(browser.version, SupportedBrowserVersion.EDGE) >= 0;

        // FIXME: This solution needs to be refactored asap!
        if (!this.shouldHideModal()) {
            this.checkSupportedVersion(browser);
        }
    }

    private checkSupportedVersion(browser: any) {
        switch (true) {
            case Object.values(Windows).includes(browser?.os):
                this.checkBrowserVersionForWindows(browser);
                break;
            case browser?.os === Os.IOS || this.isIosDesktopMode():
                this.checkBrowserVersionForiOS(browser);
                break;
            case browser?.os === Os.ANDROID:
                this.checkBrowserVersionForAndroid(browser);
                break;
            case browser?.os === Os.MAC:
                this.checkBrowserVersionForMacOS(browser);
                break;
            default:
                this.checkBrowserForOtherOS(browser);
                break;
        }
    }

    private checkBrowserVersionForMacOS(browser: any) {
        switch (browser.name) {
            case Browser.CHROME:
                if (!this.isChromeSupported) {
                    this.showRecommendChromeBrowser = true;
                }
                break;
            case Browser.FIREFOX:
                if (this.isFirefoxSupported) {
                    this.showUpdateBrowserVersion = true;
                } else {
                    this.showOutdatedBrowserForMacOs = true;
                }
                break;
            case Browser.SAFARI:
                if (this.isSafariSupported) {
                    this.showUpdateBrowserVersion = true;
                } else {
                    this.showOutdatedBrowserForMacOs = true;
                }
                break;
            case Browser.EDGE:
            case Browser.EDGE_CHROMIUM:
                if (!this.isEdgeSupported) {
                    this.showRecommendChromeBrowser = true;
                }
                break;
            default:
                this.showDownloadChromeBrowser = true;
        }
        this.store.dispatch(new ModalOpenBrowserDetectionAction());
    }

    private checkBrowserVersionForWindows(browser: any) {
        switch (browser.name) {
            case Browser.CHROME:
                if (!this.isChromeSupported) {
                    this.showRecommendChromeBrowser = true;
                }
                break;
            case Browser.FIREFOX:
                if (!this.isFirefoxEsrSupported) {
                    if (!this.isFirefoxSupported) {
                        this.showRecommendChromeBrowser = true;
                    } else {
                        this.showUpdateBrowserVersion = true;
                    }
                }
                break;
            case Browser.EDGE:
            case Browser.EDGE_CHROMIUM:
                if (!this.isEdgeSupported) {
                    this.showRecommendChromeBrowser = true;
                }
                break;
            default:
                this.showDownloadChromeBrowser = true;
        }
        this.store.dispatch(new ModalOpenBrowserDetectionAction());
    }

    private checkBrowserForOtherOS(browser: any) {
        switch (browser.name) {
            case Browser.CHROME:
                if (!this.isChromeSupported) {
                    this.showRecommendChromeBrowser = true;
                }
                break;
            case Browser.FIREFOX:
                if (!this.isFirefoxSupported) {
                    this.showRecommendChromeBrowser = true;
                } else {
                    this.showUpdateBrowserVersion = true;
                }
                break;
            default:
                this.showDownloadChromeBrowser = true;
        }
        this.store.dispatch(new ModalOpenBrowserDetectionAction());
    }

    private checkBrowserVersionForiOS(browser: any) {
        switch (browser.name) {
            case 'ios':
            case Browser.SAFARI:
                if (isSafariMobileUnsupported(browser.version)) {
                    this.showSafariNotSupported = true;
                } else if (!this.isSafariMobileSupported) {
                    this.showRecommendChromeBrowser = true;
                }

                break;
            default:
                this.showSafariSwitchRecommendation = true;
        }
        this.store.dispatch(new ModalOpenBrowserDetectionAction());
    }

    private checkBrowserVersionForAndroid(browser: any) {
        switch (browser.name) {
            case Browser.CHROME:
                if (!this.isChromeSupported) {
                    this.showRecommendChromeBrowser = true;
                }
                break;
            default:
                this.showGoogleChromeMobileRecommendation = true;
        }
        this.store.dispatch(new ModalOpenBrowserDetectionAction());
    }

    /* TODO: Remove this workaround when there is a better solution https://github.com/DamonOehlman/detect-browser/issues/163 */
    private isIosDesktopMode(): boolean {
        return navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1;
    }

    // FIXME: This solution needs to be refactored asap!
    private shouldHideModal() {
        if (window.location.href === 'https://cfg-aok.patientus.de/login' ||
            window.location.href === 'https://aok.patientus.de/login' ||
            window.location.href === 'https://aok.testing.patientus.de/login' ||
            window.location.href === 'https://cfg-aok.testing.patientus.de/login') {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        if (this.storeSubscription !== null) {
            this.storeSubscription.unsubscribe();
        }
    }
}
