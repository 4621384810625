import {AppState as RootAppState} from '../../../providers/store/app.state';

export interface HeaderState {
    remoteVideoVisible: boolean;
    hideHeaderLogo: boolean;
}

export interface AppState extends RootAppState {
    header: HeaderState;
}

export class HeaderInitialState implements HeaderState {
    remoteVideoVisible = false;
    hideHeaderLogo = false;
}
