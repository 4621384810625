import {
    RegisterAccountState,
    RegisterContractState,
    RegisterInvoiceState,
    RegisterPersonalState,
    RegisterState,
    RegisterTariffState,
    RegisterVoucherState
} from '../store/register.state';
import {
    AccountType,
    ContractType,
    InvoiceType,
    PersonalDetailsType,
    RegisterDataType,
    TariffType,
    VoucherType
} from './register-graphql.types';

const isUndefined = (value: any): boolean => typeof value === 'undefined' || value === undefined;

export class MapService {
    public static convert(rs: RegisterState): RegisterDataType {
        return {
            registerData: {
                account: MapService.convertAccount(rs.account),
                personalDetails: MapService.convertPersonal(rs.personal),
                invoice: MapService.convertInvoice(rs.invoice),
                tariff: MapService.convertTariff(rs.tariff),
                voucher: MapService.convertVoucher(rs.voucher),
                contract: MapService.convertContract(rs.contract),
            }
        };
    }

    private static convertAccount(account: RegisterAccountState): AccountType {
        return <AccountType>{...account, isValid: undefined};
    }

    private static convertPersonal(personal: RegisterPersonalState): PersonalDetailsType {
        const subjects: { id: number; name: string }[] = personal.personalDetails.subjects.map((d) => ({
            id: Number(d.id),
            name: d.itemName
        }));
        return <PersonalDetailsType>{
            ...personal.personalDetails,
            subjects,
            secondaryAddress: personal.personalDetails.additionalAddress,
            additionalAddress: undefined
        };
    }

    private static convertTariff(state: RegisterTariffState): TariffType {
        return {id: state.tariff.tariffId, name: state.tariff.name};
    }

    private static convertInvoice(invoive: RegisterInvoiceState): InvoiceType {
        return <InvoiceType>{...invoive, period: (invoive.period === 'm' ? 'MONTHLY' : 'YEARLY'), isValid: undefined};
    }

    private static convertVoucher(voucher: RegisterVoucherState): VoucherType {
        if (isUndefined(voucher.voucherCode) || voucher.voucherCode === null) {
            voucher.voucherCode = '';
        }

        if (isUndefined(voucher.voucherAgreement) || voucher.voucherAgreement === null) {
            voucher.voucherAgreement = false;
        }

        return {hash: voucher.voucherCode, terms: voucher.voucherAgreement};
    }

    private static convertContract(contrac: RegisterContractState): ContractType {
        return {terms: true};
    }
}
