import { AppState  as RootAppState } from '../../../providers/store/app.state';


// Data States #############################################################################################################################
export interface RegisterState {
    account: RegisterAccountState;
    tariff: RegisterTariffState;
    personal: RegisterPersonalState;
    invoice: RegisterInvoiceState;
    voucher: RegisterVoucherState;
    contract: RegisterContractState;
}

export interface RegisterStepState {
    isValid: boolean;
}

export class RegisterAccountState implements RegisterStepState {
    constructor(public isValid: boolean,
                public email: string,
                public password: string,
                public confirmNewsletter: boolean,
                public confirmTerms: boolean) {
    }
}

export class RegisterTariffState implements RegisterStepState {
    constructor(public isValid: boolean, public tariff: { tariffId: number; name: string }) {
    }
}


export class RegisterPersonalState implements RegisterStepState {
    constructor(public isValid: boolean, public personalDetails: PersonalDetails) {
    }
}

export interface PersonalDetails {
    title: string;
    gender: { id: 'f' | 'm'; name: string };
    firstName: string;
    surname: string;
    subjects: { id: number; itemName: string }[];
    practiceName: string;
    street: string;
    houseNumber: string;
    additionalAddress: string;
    zipCode: string;
    location: string;
    country: { id: number; name: string };
    telephone: string;
}

export class RegisterInvoiceState implements RegisterStepState {
    constructor(public isValid: boolean,
                public owner: string,
                public iban: string,
                public bic: string,
                public bank: string,
                public period: 'm' | 'y',
                public terms: boolean) {
    }
}

export class RegisterVoucherState implements RegisterStepState {
    isValid = false;
    voucherCode: string;
    isVoucherCodeValid? = false;
    voucherAgreement: boolean;
}

export class RegisterContractState implements RegisterStepState {
    isValid = false;
    someProperties: string;
}

// Behavior States #########################################################################################################################
export class RegisterInitialState implements RegisterState {
    account: RegisterAccountState = new RegisterAccountState(false, null, null, null, null);
    tariff: RegisterTariffState = new RegisterTariffState(false, {tariffId: null, name: null});
    personal: RegisterPersonalState = new RegisterPersonalState(false, <PersonalDetails>{});
    invoice: RegisterInvoiceState = new RegisterInvoiceState(false, null, null, null, null, null, false);
    voucher: RegisterVoucherState = {isValid: true, voucherCode: '', isVoucherCodeValid: false, voucherAgreement: false};
    contract: RegisterContractState = new RegisterContractState();
}

// Extend App States #######################################################################################################################

export interface AppState extends RootAppState {
    register: RegisterState;
}
