<div id="step3" class="container">
    <form [formGroup]="personalDetailsForm" (ngSubmit)="onSubmit()" role="form">
        <h2>
            <span>03</span> {{ 'REGISTER.PERSONAL-DETAILS.PERSONAL-DATA' | translate }}</h2>
        <div class="box1">
            <div class="box_content">
                <h4>{{ 'REGISTER.PERSONAL-DETAILS.PERSONAL-DATA' | translate }}</h4>
                <select id="gender" formControlName="gender" class="select_xl validate filled">
                    <option value="" selected>{{ 'REGISTER.PERSONAL-DETAILS.CHOOSE-GENDER' | translate }}</option>
                    <option *ngFor="let gender of genders" [ngValue]="gender">{{gender.name}}</option>
                </select>
                <input formControlName="title" type="text" value=""
                       placeholder="{{ 'REGISTER.PERSONAL-DETAILS.TITLE' | translate }}">
                <div *ngIf="isInvalidAndTouched('firstName')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-FIRST-NAME' | translate }}
                </div>
                <input formControlName="firstName" type="text" value=""
                       placeholder="{{ 'REGISTER.PERSONAL-DETAILS.FIRST-NAME' | translate }}">
                <div *ngIf="isInvalidAndTouched('surname')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-SURNAME' | translate }}
                </div>
                <input formControlName="surname" type="text" value=""
                       placeholder="{{ 'REGISTER.PERSONAL-DETAILS.SURNAME' | translate }}">
                <div *ngIf="isInvalidAndTouched('subjects')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-SUBJECTS' | translate }}
                </div>
                <div class="chosen-container chosen-container-multi"
                     title=""
                     id="qualificationlist_chosen">
                    <angular2-multiselect
                        formControlName="subjects"
                        [data]="qualifications"
                        [settings]="qualificationDropDownSettings"
                    ></angular2-multiselect>
                </div>
            </div>
        </div>
        <div class="box1">
            <div class="box_content">
                <h4>{{ 'REGISTER.PERSONAL-DETAILS.PRACTICE-INFORMATION' | translate }}</h4>
                <input formControlName="practiceName"
                       type="text"
                       value=""
                       [placeholder]="'REGISTER.PERSONAL-DETAILS.PRACTICE-NAME' | translate">
                <div *ngIf="isInvalidAndTouched('street')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-STREET' | translate }}
                </div>
                <input formControlName="street"
                       type="text"
                       value=""
                       [placeholder]="'REGISTER.PERSONAL-DETAILS.STREET' | translate">
                <div *ngIf="isInvalidAndTouched('houseNumber')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-HOUSE-NUMBER' | translate }}
                </div>
                <input formControlName="houseNumber"
                       type="text"
                       value=""
                       [placeholder]="'REGISTER.PERSONAL-DETAILS.HOUSE-NUMBER' | translate">
                <input formControlName="additionalAddress"
                       type="text"
                       value=""
                       [placeholder]="'REGISTER.PERSONAL-DETAILS.ADDITION-ADDRESS' | translate">
                <div *ngIf="isInvalidAndTouched('zipCode')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-POSTAL-CODE' | translate }}
                </div>
                <input formControlName="zipCode"
                       type="text"
                       value=""
                       [placeholder]="'REGISTER.PERSONAL-DETAILS.POSTALCODE' | translate">
                <div *ngIf="isInvalidAndTouched('location')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-LOCATION' | translate }}
                </div>
                <input formControlName="location"
                       type="text"
                       value=""
                       [placeholder]="'REGISTER.PERSONAL-DETAILS.LOCATION' | translate">
                <div *ngIf="isInvalidAndTouched('country')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-COUNTRY' | translate }}
                </div>
                <select id="country"
                        formControlName="country"
                        [compareWith]="compareByCountry"
                        class="select_xl validate filled">
                    <option *ngFor="let country of countries"
                            [ngValue]="country">{{country.name}}
                    </option>
                </select>
                <div *ngIf="isInvalidAndTouched('telephone')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.EMPTY-TELEPHONE' | translate }}
                </div>
                <div *ngIf="isInvalidAndTouched('phone')" class="error">
                    {{ 'REGISTER.PERSONAL-DETAILS.ERRORS.TELEPHONE-PATTERN' | translate }}
                </div>
                <input formControlName="telephone"
                       type="text"
                       value=""
                       [placeholder]="'REGISTER.PERSONAL-DETAILS.TELEPHONE' | translate">
                <input [ngClass]=" {'inactive':!personalDetailsForm.valid }"
                       id="personalDetailsSubmit"
                       type="submit"
                       [value]="'REGISTER.PERSONAL-DETAILS.NEXT-STEP' | translate">
            </div>
        </div>
    </form>
</div>
