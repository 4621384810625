<div class="background-wrapper-image" [ngStyle]="{backgroundImage: 'url(' + (backgroundImg$ | async).backgroundImage + ')'}">
    <ng-container *ngIf="{ appointments: appointments$ | async } as waitingRoom">
        <div class="appointment-info-for-patients-wrapper"
             *ngIf="waitingRoom?.appointments?.length > 0">
            <app-multi-user-appointment-info-for-patients
                *ngFor="let appointment of waitingRoom.appointments"
                [appointment]="appointment">
            </app-multi-user-appointment-info-for-patients>
        </div>
    </ng-container>
</div>
