import { NgModule } from '@angular/core';
import { CookieModule } from 'ngx-cookie';
import { ExternLoginComponent } from './extern-login/extern-login.component';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

const externalLoginRoutes: Routes = [
    {path: 'external-login/:token', component: ExternLoginComponent},
];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        RouterModule.forChild(externalLoginRoutes),
        CookieModule.forChild()
    ],
    declarations: [
        ExternLoginComponent
    ],
    providers: [

    ],
    exports: [
        RouterModule
    ]
})
export class ExternalLoginModule {
}
