import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState, RegisterInvoiceState, RegisterPersonalState } from '../store/register.state';
import { RegisterAddInvoiceAction } from '../store/register.action';
import { StepInvoiceFormBuilder } from './step-invoice-form.builder';
import { ScrollService } from '../providers/scroll.service';
import { AnalyticsService } from '../../../providers/analytics/analytics.service';
import { Observable } from 'rxjs';
import { NextStepFrom } from '../navigation/navigation.types';

export const GERMAN_DEFAULT_BIC = '00000000';

@Component({
    selector: 'app-step-invoice',
    templateUrl: './step-invoice.component.html',
    styleUrls: ['./step-invoice.component.scss']
})
export class StepInvoiceComponent {

    invoiceForm: UntypedFormGroup;
    personal$: Observable<RegisterPersonalState>;
    public shouldHideBic = false;

    constructor(private store: Store<AppState>,
                private translate: TranslateService,
                private scroll: ScrollService,
                private analytics: AnalyticsService,
                fb: UntypedFormBuilder) {
        this.invoiceForm = StepInvoiceFormBuilder.build(fb);
        this.changeBicOnCountryChange();
        this.invoiceForm.valueChanges.subscribe(this.dispatch.bind(this));
    }

    changeBicOnCountryChange() {
        this.personal$ = this.store.select(state => state.register.personal);
        this.personal$.subscribe(personal => {
            if (!personal.hasOwnProperty('personalDetails')) {
                return;
            }
            if (!personal.personalDetails.hasOwnProperty('country')) {
                return;
            }
            const country = personal.personalDetails.country;
            const bicFiled = this.invoiceForm.controls['bic'];
            if (country.id === 52) {
                bicFiled.setValue(GERMAN_DEFAULT_BIC);
                bicFiled.markAsTouched();
                this.shouldHideBic = true;
            } else {
                bicFiled.setValue('');
                bicFiled.markAsUntouched();
                this.shouldHideBic = false;
            }
        });
    }

    onSubmit() {
        if (this.invoiceForm.valid) {
            this.scroll.scrollToStep(NextStepFrom.INVOICE);
            this.analytics.trackNewPageView('/confirmSigningContract.html', 'Confirm Signing Contract');
        } else {
            Object.keys(this.invoiceForm.controls).forEach(key => {
                this.invoiceForm.get(key).markAsTouched();
            });
            this.analytics.trackWrongFormInputEvent(this.invoiceForm);
        }
    }

    dispatch(formvalue) {
        const YEARLY = 'y';

        const invoice = new RegisterInvoiceState(
            this.invoiceForm.valid,
            formvalue.owner,
            formvalue.iban,
            formvalue.bic,
            formvalue.bank,
            YEARLY,
            formvalue.terms
        );
        this.store.dispatch(new RegisterAddInvoiceAction(invoice));
        return formvalue;
    }
}
