<div class="faq-side-secton" [class.open]="openSidebar">
    <div class="faq-list-space">
        <ng-content></ng-content>
    </div>
    <div class="faq-toggle-icon" (click)="toggleSidebar();">
        <i class="fas fa-question-circle" *ngIf="!openSidebar"></i>
        <i class="fas fa-caret-left" *ngIf="openSidebar"></i><br>
        <span>{{ 'LOGIN.FAQ-TOGGLE-TEXT' | translate }}</span>
    </div>
</div>
