import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CustomValidators} from '../../../forms/validators/custom-validator';

export class StepVoucherFormBuilder {

    public static build(formBuilder: UntypedFormBuilder): UntypedFormGroup {

        return formBuilder.group({
            voucherCode: [''],
            voucherAgreement: new UntypedFormControl({value: null, disabled: true}, CustomValidators.conditionalRequiredTrue('voucherCode'))
        });
    }
}
