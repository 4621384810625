import { map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Profile } from '../../../profile/store/profile.entity';
import { ProfileService } from '../../../profile/providers/profile.service';
import { AuthStateProvider } from '../../../auth/auth-state.provider';
import { AppState } from '../../store/header.state';
import { BrandingService } from '../../../branding/providers/branding.service';
import { BrandingState } from '../../../branding/store/branding.state';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    public remoteVideoVisibility$: Observable<boolean>;
    public hideHeaderLogo$: Observable<boolean>;
    public isAuthenticated$: Observable<boolean>;
    public profileData$: Observable<Profile>;
    public brandingSettings$: Observable<BrandingState>;
    public logoUrls$: Observable<any>;

    constructor(private profileService: ProfileService,
                private authProvider: AuthStateProvider,
                private store: Store<AppState>,
                private brandingService: BrandingService) {
        this.profileData$ = profileService.getCurrentProfileDataObserver();
        this.isAuthenticated$ = authProvider.isAuthenticated();
        this.remoteVideoVisibility$ = this.store.select(s => s.header.remoteVideoVisible);
        this.hideHeaderLogo$ = this.store.select(s => s.header.hideHeaderLogo);
        this.brandingSettings$ = this.brandingService.getBrandingSettingsFromStore();
        this.logoUrls$ = this.brandingSettings$.pipe(
            map(settings => {
                const logo = settings.logoUrl || '/assets/img/general/logo_new.svg';
                const smallLogo = settings.smallLogoUrl || '/assets/img/general/patientus-logo-cropped.svg';
                return {logoUrl: logo, smallLogoUrl: smallLogo};
            }));
    }

    public logout() {
        this.authProvider.logOut();
    }
}
