import { MODAL_CLOSE, MODAL_OPEN, MODAL_OVERLAY_COMPONENT, ModalAction } from './modal.action';
import { ModalInitialState, ModalState } from './modal.state';

export function modalReducer(state: ModalState = new ModalInitialState(), action: ModalAction): ModalState {
    switch (action.type) {
        case MODAL_OPEN:
            return {
                message: action.payload.message,
                isActive: true,
                category: action.payload.category,
                component: null,
                style: action.payload.style
            };
        case MODAL_CLOSE:
            return new ModalInitialState();
        case MODAL_OVERLAY_COMPONENT:
            return {
                message: action.payload.message,
                isActive: true,
                category: action.payload.category,
                component: action.payload.component,
                style: action.payload.style
            };
        default:
            return state;
    }
}
