import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register.component';
import { NavigationComponent } from './navigation/navigation.component';
import { StepAccountComponent } from './step-account/step-account.component';
import { StepTariffComponent } from './step-tariff/step-tariff.component';
import { StepVoucherComponent } from './step-voucher/step-voucher.component';
import { StepPersonalComponent } from './step-personal/step-personal.component';
import { StepInvoiceComponent } from './step-invoice/step-invoice.component';
import { SoftNoteTaxComponent } from './soft-note-tax/soft-note-tax.component';
import { SoftNoteFeeComponent } from './soft-note-fee/soft-note-fee.component';
import { StepContractComponent } from './step-contract/step-contract.component';
import { registerReducer } from './store/register.reducer';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ScrollService } from './providers/scroll.service';
import { TariffDetailsComponent } from './step-tariff/tariff-details/tariff-details.component';
import { AnalyticsService } from '../../providers/analytics/analytics.service';
import { RegisterService } from './providers/register.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RegisterEffects } from './store/register.effect';
import { registerRoutes } from './register.routes';
import { RegistartionFailedComponent } from './pages/registartion-failed/registartion-failed.component';
import { RegistartionSuccessfulComponent } from './pages/registartion-successful/registartion-successful.component';
import { FooterModule } from '../footer/footer.module';


@NgModule({
    declarations: [
        RegisterComponent,
        NavigationComponent,
        StepAccountComponent,
        StepTariffComponent,
        StepVoucherComponent,
        StepPersonalComponent,
        StepInvoiceComponent,
        SoftNoteTaxComponent,
        SoftNoteFeeComponent,
        StepContractComponent,
        TariffDetailsComponent,
        StepContractComponent,
        RegistartionFailedComponent,
        RegistartionSuccessfulComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature('register', registerReducer),
        EffectsModule.forFeature([RegisterEffects]),
        RouterModule.forChild(registerRoutes),
        TranslateModule.forChild(),
        AngularMultiSelectModule,
        FooterModule
    ],
    providers: [
        RegisterService,
        ScrollService,
        AnalyticsService
    ],
    exports: [
        RouterModule
    ]
})
export class RegisterModule {
}
