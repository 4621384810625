<div id="step2" class="container">
    <div>
        <h2><span>02</span> {{ 'REGISTER.TARIFF.CHOOSE' | translate }}</h2>
        <div class="tarif_background row">
            <div *ngFor="let tariff of tariffs" class="col-lg-6 col-md-12">
                <div class="tarifbox t_{{tariff.name}} ">
                    <div class="tarif_content">
                        <div class="price">
                            <div class="price-item">
                                {{tariff.price}}{{tariff.currency}}
                            </div>
                            <div class="price-info">
                                <span>{{ 'REGISTER.TARIFF.TAX' | translate }}</span>
                                <br>
                                <span class="payment-time">{{ 'REGISTER.TARIFF.PAYMENT_ROUTINE' | translate }}</span>
                            </div>
                        </div>
                        <p>{{tariff.duration}}</p>
                        <span class='tariff-title'>{{ 'REGISTER.TARIFF.TITLE' | translate }}</span>
                        <h3>{{tariff.name}}</h3>
                        <app-tariff-details [details]="tariff.details"></app-tariff-details>
                        <a class="tarif_choose" (click)='onSelectTariff(tariff.tariffId)'> {{ 'REGISTER.TARIFF.CHOOSE' |
                            translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
